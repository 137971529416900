<template>
  <div>
    <h3 class="mb-4" v-if="prompt">{{ prompt }}</h3>
    <v-row>
      <v-col v-if="settings.chartType === 'skillDistribution' && tagCategories.length" style="max-width: 400px;">
        <v-autocomplete
          :items="tagCategories"
          v-model="tagCategoryId"
          :label="`${settings.tagTypeName} Category`"
          item-text="name"
          item-value="tag_category_id"
          clearable
          outlined
          dense
          class="roundish"
        ></v-autocomplete>
      </v-col>
      <v-col v-if="settings.chartType === 'skillDistribution'" style="max-width: 400px;">
        <v-autocomplete
          :items="tagValues"
          v-model="tagValueId"
          :label="settings.tagTypeName"
          item-text="value"
          item-value="tag_value_id"
          clearable
          outlined
          dense
          class="roundish"
        ></v-autocomplete>
      </v-col>
      <template>
        <v-col v-for="(h, hi) in hierarchies"
          :key="'h' + hi"
          style="max-width: 400px;">
          <HierarchyTreePicker
            v-if="useTreePicker"
            v-model="h.treePickerValue"
            :options="h.treePickerOptionsActive"
            :fulldetail="true"
            :label="h.label"
            :placeholder="'Choose a ' + h.label"
            :clearable="true"
            :hierarchyType="h"
            :multiple="true"
            :limit="1"
            dense
            @changeNodeIds="onHryNodeIdSelect(h, $event)"
          >
          </HierarchyTreePicker>
          <v-autocomplete
            v-else-if="!h.selectNew"
            dense
            outlined
            clearable
            class="roundish"
            auto-select-first
            :ref="'hcb' + hi"
            :return-object="false"
            :items="h.values"
            item-value="text"
            item-text="text"
            :label="h.label"
            multiple
            v-model="h.hierarchy_text"
            @focus="focusHTID = hi"
            @keyup="searchText($event, h)"
            @keyup.enter.exact="pickValue(h)"
            @keyup.ctrl.enter="pickValue(h, true)"
            :filter="utils.comboFilterPicker"
            hide-details
            :append-icon="h.icon"
          >
            
            <template v-slot:prepend-item>
              <div
                v-if="h.showNewPrompt"
                style="padding: 0px 0px 10px 10px; font-size: 14px"
              >
                <div>
                  Press <kbd>Enter</kbd> to pick the highlighted item
                </div>
              </div>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ h.not_in_list_message }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </template>
    </v-row>
    <div :class="`mt-6 mb-6 dt_${displayType}`" :style="{ 'min-height': `${chartHeight}px` }">
      <div v-for="chart in charts" :key="chart.id" class="chartHolder">
        <div :id="chart.id"></div>
        <div class="centralTitle" v-if="displayType === 'pieset'">
          <h4>{{ chart.data.reduce((cum, curr) => cum += parseInt(curr[measure]), 0) }}</h4>
          <p>{{ measure }}</p>
        </div>
        <h3 class="bottomTitle" v-if="displayType === 'pieset'">{{ chart.data[0][category] }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import utils from "@/common/utils.js";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import { mapState } from "vuex";
const d3 = require("d3");
const dimple = require("@/common/dimple.js");

export default {
  name: "landingChart",
  components: {
    HierarchyTreePicker
  },
  data: function () {
    return {
      isLoading: false,
      tagCategoryId: null,
      tagValueId: null,
      category: "Job",
      dimension: "Rating",
      measure: "Number of Jobs",
      chartId: "chart_" + utils.makeid(10),
      displayType: "bars",
      charts: [],
      element: null,
      chartWidth: 0,
      chart: null,
      bars: null,
      colours: [
        "#50B3CB", // Blue
        "#2cd946", // Green
        "#ED742D", // Orange
        "#FFCB00", // Yellow
        "#EA3785", // Pink
        "#5E2872", // Purple
        "#8DD3C7", // Turquoise
        "#CCEBC5", // Pale Blue
        "#FFFFB3", // Pale Yellow
        "#BEBADA", // Lavender
        "#FCCDE5", // Pink
        "#D9D9D9", // Grey,
      ],
      hierarchies: [],
      selectedHierarchies: []
    };
  },
  props: {
    definition: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
  },
  mounted() {
    this.tagValueId = this.settings.initialTagValueId;
    this.setupHierarchies();
    this.$nextTick(() => {
      window.addEventListener('resize', this.setChartSize);
    });
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.setChartSize);
  },
  watch: {
    hierarchiesLoading() {
      this.setupHierarchies();
    },
    'settings.initialTagValueId'(val) {
      if (val)
        this.tagValueId = val;
    },
    'settings.chartType'() {
      this.fetchData();
    },
    tagValueId() {
      this.fetchData();
    }
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagCategories: (state) => state.hierarchies.tagCategories,
      docTypes: (state) => state.hierarchies.docTypes,
      lifecycleStatuses: (state) => state.hierarchies.lifecycleStatuses,
      config: (state) => state.settings.config,
    }),
    settings() {
      return this.definition;
    },
    prompt() {
      switch (this.settings.chartType) {
        case "skillDistribution":
          return `Choose a ${this.singularize(this.settings.tagTypeName) }`;
        case "skillRequirements":
          return `Choose a Job`;
        default:
          return "";
      }
    },
    tagType() {
      return this.tagTypes?.find(tt => tt.tag_type_name === this.settings.tagTypeName) || null;
    },
    tagValues() {
      let values = this.tagType?.values || [];
      if (this.tagCategoryId)
        values = values.filter(v => v.tag_category_id === this.tagCategoryId)
      
      return values;
    },
    chartHeight() {
      return this.settings.height || this.displayType === 'pieset' ? 200 : 300;
    },
    useTreePicker() {
      return !!this.config.settings.find(
        (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
      );
    }
  },
  created() {},
  methods: {
    setupHierarchies() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.hierarchies = this.$store.state.hierarchies.hierarchies.map(
          (h) => {
            let hry = { ...h };
            switch (hry.label.toLowerCase()) {
              case "geography":
              case "location":
                h.icon = "mdi-map-marker-outline";
                break;
              default:
                h.icon = "mdi-menu-down";
                break;
            }
            return hry;
          }
        );
      } else {
        this.resetTreePicker();
      }
    },
    resetTreePicker() {
      if (this.hierarchies.length) {
        this.hierarchies.forEach((h) => (h.treePickerValue = []));
      }
    },
    onHryNodeIdSelect(ht, selNodeIds) {
      let sht = this.selectedHierarchies.find(s => s.ht_id === ht.ht_id);
      if (!sht) {
        this.selectedHierarchies.push({ ht_id: ht.ht_id, hr_node_ids: selNodeIds });
      } else {
        sht.hr_node_ids = selNodeIds;
      }

      this.fetchData();
    },
    fetchData() {
      this.charts.forEach(c => {
        d3.select(c.element).selectAll("*").remove();
      });
      this.charts.length = 0;

      let dataEndpoint = "";
      let data = {};
      switch (this.settings.chartType) {
        case "skillDistribution":
          if (this.tagValueId) {
            dataEndpoint = "report/tagDistribution";
            data = {
              tag_value_id: this.tagValueId,
              selectedHierarchies: this.selectedHierarchies
            }
          }
          break;
        case "skillRequirements":
          if (this.selectedHierarchies.some(h => h.hr_node_ids.length)) {
            dataEndpoint = "report/tagRequirements";
            data = {
              selectedHierarchies: this.selectedHierarchies
            }
          }
          break;
        case "documentsBySkillCategory":
          if (this.selectedHierarchies.some(h => h.hr_node_ids.length)) {
            dataEndpoint = "report/tagCategoriesByJob";
            data = {
              selectedHierarchies: this.selectedHierarchies
            }
          }
          break;
      }
      
      if (!dataEndpoint)
        return;

      let possibleError = false;
      this.isLoading = true;
      axios
        .post(dataEndpoint, data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            this.category = data.categoryField;
            this.dimension = data.dimensionField;
            this.measure = data.measureField;
            this.maxGroupedCount = data.maxGroupedCount;
            this.displayType = data.displayType || "bars";

            switch (this.displayType) {
              case "pieset":
                this.charts.push(...data.groupedValues.map((g, gi) => {
                  return {
                    id: `${this.chartId}_${gi}`,
                    element: null,
                    chart: null,
                    bars: null,
                    data: g
                  }
                }));
                break;
              default:
                this.charts.push({
                  id: this.chartId,
                  element: null,
                  chart: null,
                  bars: null,
                  data: data.flatValues
                });
                break;
            }
            this.$nextTick(() => this.drawCharts())
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    drawCharts() {
      this.charts.forEach(chart => {
        chart.element = document.getElementById(chart.id);
        if (this.displayType === "pieset")
          this.drawPieChart(chart);
        else
          this.drawBarChart(chart);
      })
    },
    drawPieChart(chart) {
      const vm = this;
      
      d3.select(chart.element).selectAll("*").remove();
      let svg = dimple.newSvg(chart.element, 100, 100),
        myChart = new dimple.chart(svg, chart.data);
      
      this.assignChartColours(myChart, chart.data);

      //myChart.setBounds(10, "15%", "70%", "90%"); // x,y,w,h
      myChart.setBounds(0, 0, 100, 100); // x,y,w,h
      
      //const x = myChart.addCategoryAxis("x", this.category);
      myChart.addMeasureAxis("p", this.measure);
      const ring = myChart.addSeries(this.dimension, dimple.plot.pie);
      ring.innerRadius = "60%";

      //x.addOrderRule(this.dimension, true);
      //const maxValue = this.maxGroupBy(chart.data, this.category, this.measure);
      //y.ticks = maxValue > 10 ? 10 : maxValue;
      //y.tickFormat = 'd';

      //if (!this.settings.hideLegend)
      //  myChart.addLegend("90%", 0, "10%", "100%", "left");


      //ring.afterDraw = chartHelper.pieLabels(svg);

      ring.addEventHandler("click", function (ev) {
        vm.clickHandler(ev);
      });
      ring.getTooltipText = (ev) => {
        const dimension = ev.target.classList[2].replace('dimple-', '');
        const regex = /[\s()]/g;
        const values = chart.data.filter(d => d[this.dimension].toLowerCase().replace(regex, '-') === dimension)

        if (values.length)
          return [ `${values[0][this.dimension]}: ${d3.format(",")(values.reduce((cum, d) => cum + d[this.measure], 0))}` ];
        else
          return [];
      };

      myChart.draw();

      chart.chart = myChart;
      chart.bars = ring;
    },
    drawBarChart(chart) {
      const vm = this;
      
      this.setChartSize();
      d3.select(chart.element).selectAll("*").remove();
      let svg = dimple.newSvg(chart.element, this.chartWidth, this.chartHeight),
        myChart = new dimple.chart(svg, chart.data);
      
      this.assignChartColours(myChart, chart.data);

      //myChart.setBounds(10, "15%", "70%", "90%"); // x,y,w,h
      myChart.setBounds(50, 0, this.settings.hideLegend ? "100%" : "85%", this.chartHeight - 50); // x,y,w,h
      
      const x = myChart.addCategoryAxis("x", this.category);
      const y = myChart.addMeasureAxis("y", this.measure);
      const bars = myChart.addSeries(this.dimension, dimple.plot.bar);
      x.addOrderRule(this.dimension, true);
      const maxValue = this.maxGroupBy(chart.data, this.category, this.measure);
      y.ticks = maxValue > 10 ? 10 : maxValue;
      y.tickFormat = 'd';

      if (!this.settings.hideLegend)
        myChart.addLegend("90%", 0, "10%", "100%", "left");

      bars.addEventHandler("click", function (ev) {
        vm.clickHandler(ev);
      });
      bars.getTooltipText = (ev) => {
        const dimension = ev.target.classList[2].replace('dimple-', '');
        const category = ev.target.classList[3].replace('dimple-', '');
        const regex = /[\s()]/g;
        const values = chart.data.filter(d => d[this.dimension].toLowerCase().replace(regex, '-') === dimension && d[this.category].toLowerCase().replace(regex, '-') === category)

        if (values.length)
          return [ `${values[0][this.category]}`, `${values[0][this.dimension]}: ${d3.format(",")(values.reduce((cum, d) => cum + d[this.measure], 0))}`];
        else
          return [];
      };

      myChart.draw(200);

      chart.chart = myChart;
      chart.bars = bars;
    },
    maxGroupBy(array, groupBy, measure) {
      const grouped = array.reduce(function(res, value) {
        if (!res[value[groupBy]]) {
          res[value[groupBy]] = 0;
        }
        res[value[groupBy]] += parseInt(value[measure]);
        return res;
      }, {});
      return Math.max(...Object.keys(grouped).map(p => grouped[p]));
    },
    setChartSize() {
      if (this.displayType === 'pieset')
        return;

      this.charts.forEach(c => {
        this.chartWidth = c.element?.parentElement.offsetWidth - 25;
        //this.chartHeight = this.settings.hideLegend ? this.chartWidth : this.chartWidth * 0.6;

        if (c.chart) {
          c.chart.svg
            .attr("width", this.chartWidth)
            .attr("height", this.chartHeight);
          d3.select(c.element).selectAll(".barLabel").remove();
          c.chart.draw(5);
        }
      })
      
    },
    redrawChart() {
      //d3.select(this.element).selectAll(".barLabel").remove();
      this.charts.forEach(chart => chart.draw(200));
    },
    assignChartColours(chart, data) {
      const dimensionColours = [];
      let getColour = (v) => {
        let col = { value: v, colour: this.colours[dimensionColours.length] };
        dimensionColours.push(col);
        return col.colour;
      };
      let processed = [];
      data.forEach((cd) => {
        let value = cd[this.dimension];
        if (processed.indexOf(value) < 0) {
          processed.push(value);
          chart.assignColor(value, getColour(value));
        }
      });
    },
    clickHandler(ev) {
      if (ev.seriesValue && ev.seriesValue.length)
        this.rowSelected(ev.seriesValue[0]);
    },
    rowSelected(value, ignore) {
      if (ignore) return;
      if (
        this.section.view.filterCriteria.length &&
        !this.section.view.filterCriteria[0].ht_id
      ) {
        this.removeFilter(0);
      } else if (
        this.section.view.data.length > 1 &&
        (this.section.view.allowDrilldown ||
          !this.section.view.filterCriteria.length)
      )
        this.$emit("rowSelected", this.section.view, value);
    },
    singularize(word) {
      const endings = {
          ves: 'fe',
          ies: 'y',
          i: 'us',
          zes: 'ze',
          ses: 's',
          es: 'e',
          s: ''
      };
      return word.replace(
          new RegExp(`(${Object.keys(endings).join('|')})$`), 
          r => endings[r]
      );
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.dt_pieset {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .chartHolder {
    margin: 0 10px;
    position: relative;
    .centralTitle {
      text-align: center;
      position: absolute;
      top: 32px;
      left: 0;
      width: 100%;
      > h4 {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }
      > p {
        font-size: 12px;
        margin-top: -5px;
      }
    }
    .bottomTitle {
      text-align: center;
      top: 100px;
      left: 0;
      width: 100%;
      white-space: wrap;
      font-size: 12px;
    }
  }
}

::v-deep {
  .dimple-axis.dimple-custom-axis-title {
    font-size: 12px !important;
    font-weight: bold !important;
  }
}
</style>
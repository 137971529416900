
<script setup>
import { ref, onBeforeMount, onUpdated, watch, reactive, getCurrentInstance, computed } from 'vue';
import { inject } from 'vue';
import SkillWorkspace from "@/components/skills/cSkillWorkspace";
import SkillAdmin from "@/components/skills/cSkillAdmin";

const loginState = inject('loginState');
const store = inject('store');

const skillManagerData = reactive({
    loading: true,
    tabs: [
      "Skills Dashboard",
      "Skills Workspace",
      "Skills Framework Admin",
      "Market Insights"
    ],
    skillTab: 0
})
</script>
<template>
  <v-card class="skillsManager" flat>
    <v-tabs class="skillsTabs" v-model="skillManagerData.skillTab" grow color="#48A401" background-color="transparent">
      <v-tab class="tab ma-0 pa-0">
        Skills Dashboard
      </v-tab>
      <v-tab class="tab ma-0 pa-0">
        Skills Workspace
      </v-tab>
      <v-tab class="tab ma-0 pa-0">
        Skills Framework Admin
      </v-tab>
      <v-tab class="tab ma-0 pa-0">
        Market Insights
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="skillManagerData.skillTab" style="">
      <v-tab-item class="tabItem">
        Dashboard  
      </v-tab-item>
      <v-tab-item class="tabItem">
        <SkillWorkspace></SkillWorkspace> 
      </v-tab-item>    
      <v-tab-item class="tabItem">
        <SkillAdmin></SkillAdmin>       
      </v-tab-item>   
      <v-tab-item class="tabItem">
        Insights     
      </v-tab-item>     
    </v-tabs-items>   
  </v-card>  
</template>
<style lang="scss" scoped>
.skillsManager { 
  width: 100%;
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
}

.skillsTabs {
  width: 50%;
  max-height: calc(100vh - 100px);

}

.tabItem {
  height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow: hidden scroll;
    padding-left: 5px;
}
</style>
<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn-toggle v-model="view" tile group>
          <v-btn value="tags"> Administer Tags </v-btn>
          <v-btn value="gradings"> Administer Tag Gradings </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-card
      class="mainPanel mt-6"
      v-if="!hierarchiesLoading && view === 'tags'"
    >
      <v-card-title class="pb-0">
        <v-row dense>
          <v-col cols="2">
            <v-select
              :items="tagTypes"
              :required="true"
              label="Tag Type"
              item-text="tag_type_name"
              item-value="tag_type_id"
              v-model="currentTagType"
              hide-details
              return-object
              dense
              outlined
              append-icon="mdi-plus"
              @click:append="addTagType"
            ></v-select>
          </v-col>
          <v-col cols="2" v-if="currentTagType">
            <v-switch v-model="hideInactive" label="Hide Inactive"></v-switch
          ></v-col>
          <v-col cols="4" v-if="currentTagType">
            <v-btn color="primary" @click="addTagValue"
              ><v-icon class="pr-4">mdi-tag</v-icon> Add new
              {{ currentTagType.tag_type_name }} Value</v-btn
            ></v-col
          >
          <v-col cols="2" v-if="currentTagType && showRating">
            <v-btn color="primary" @click="editTagTypeRating"
              ><v-icon class="pr-4">mdi-tag-text</v-icon> Add new
              {{ currentTagType.tag_type_name }} Rating</v-btn
            ></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text v-if="currentTagType">
        <v-row>
          <v-col :cols="showRating ? 8 : 12">
            <v-data-table
              :headers="listColumns"
              :items="listData"
              :sort-by.sync="listView.sortColumn"
              :sort-desc.sync="listView.sortDesc"
              :dense="listView.density == 'dense'"
              :class="['mt-2', listView.density]"
              :page.sync="listView.currentPage"
              hide-default-footer
              :items-per-page="listView.itemsPerPage"
              :fixed-header="!!listView.tableHeight"
              :height="listView.tableHeight"
            >
              <template v-slot:top>
                <v-row
                  ><v-col>
                    <!-- <v-spacer></v-spacer -->
                    <v-chip
                      small
                      v-for="(f, fi) in filterList"
                      :key="'f' + fi"
                      close
                      @click:close="removeFilter(f)"
                    >
                      {{ f.title }}</v-chip
                    ></v-col
                  >
                  <!-- <v-col cols="2"><v-btn
                  small
                  color="primary"
                  class="mb-4"
                  @click="addTagValue"
                  >Add new {{ currentTagType.tag_type_name }} Value</v-btn
                >
                </v-col
              > -->
                </v-row>
              </template>
              <!-- eslint-disable vue/valid-v-slot -->
              <template
                v-for="(h, hi) in listColumns"
                v-slot:[`header.${h.value}`]="{ header }"
                ><div style="white-space: nowrap" :key="h.value">
                  {{ header.text }}
                  <v-menu
                    v-if="header.filterSettings"
                    v-model="header.filterSettings.show"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    @input="openlistViewFilter(header.filterSettings)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="blue" small v-bind="attrs" v-on="on" icon>
                        <v-icon small>
                          {{
                            header.filterSettings.isActive
                              ? "mdi-filter-settings"
                              : "mdi-filter-outline"
                          }}</v-icon
                        >
                      </v-btn>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ header.text }} Filters</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="filterClear(header.filterSettings)"
                            >
                              Clear
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="header.filterSettings.show = false"
                            >
                              Close
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="filterHeading(header.filterSettings)"
                            >
                              Apply
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                placeholder="type to filter values"
                                hide-details
                                append-icon="mdi-check-all"
                                @click:append="
                                  filterSearchAll(header.filterSettings)
                                "
                                @input="filterSearch(header.filterSettings)"
                                dense
                                clearable
                                v-model="header.filterSettings.searchText"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="header.filterSettings.pages > 1"
                          >
                            <v-pagination
                              v-model="header.filterSettings.page"
                              :length="header.filterSettings.pages"
                              :total-visible="7"
                            ></v-pagination>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-list style="max-height: 100px; overflow: auto">
                        <v-list-item
                          dense
                          v-for="(v, vi) in header.filterSettings.values.filter(
                            (x) => !x.visible && x.selected
                          )"
                          :key="'head' + hi + 'vs' + vi"
                        >
                          <v-list-item-content>
                            <v-switch
                              v-model="v.selected"
                              color="blue"
                              :label="`${v.text} (${v.count})`"
                            ></v-switch>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list
                        style="max-height: 400px; overflow: none"
                        @wheel.stop="
                          filterScroll($event, header.filterSettings)
                        "
                      >
                        <v-list-item
                          dense
                          v-for="(v, vi) in header.filterSettings.values.filter(
                            (x) =>
                              x.visible && x.page === header.filterSettings.page
                          )"
                          :key="'head' + hi + 'v' + vi"
                        >
                          <v-list-item-content>
                            <v-switch
                              v-model="v.selected"
                              color="blue"
                              :label="`${v.text} (${v.count})`"
                            ></v-switch>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </template>
              <!-- eslint-enable vue/valid-v-slot -->
              <template v-slot:item="{ item }">
                <tr @click="editTag(item)">
                  <td
                    v-for="col in listColumns"
                    :key="col.value"
                    :class="!item.active ? 'inactive-row' : ''"
                    :title="!item.active ? 'Value is inactive' : null"
                  >
                    <v-btn
                      v-if="col.value === 'tag_value_ratings'"
                      small :color="item.ratings?.length ? 'primary' : ''"
                      @click.stop="showValueRatings(item)"
                      >{{ item.ratings?.length ? "View" : "Add" }}</v-btn
                    >
                    <template v-else>
                      {{ item[col.value] }}
                      <v-icon
                        v-if="!item.active && col.value === 'value'"
                        color="red"
                        >mdi-emoticon-dead-outline</v-icon
                      ></template
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div
              class="d-flex justify-space-between footer-actions primary-background"
            >
              <PageDescription
                :totalItems="listData.length"
                :pageSize="listView.itemsPerPage"
                :currentPage="listView.currentPage"
              />
              <Pagination
                :totalItems="listData.length"
                :pageSize="listView.itemsPerPage"
                :currentPage="listView.currentPage"
                @pageNavigation="listView.currentPage = $event"
              />
              <div>
                <v-btn
                  color="primary"
                  small
                  outlined
                  class="mx-2"
                  @click="exportLVToCSV"
                >
                  <span>Export</span>
                </v-btn>
                <v-menu offset-y v-if="listView.pageSize === 0" z-index="301">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!$loginState.readOnly"
                      outlined
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPageText }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-for="item in itemsPerPageOptions"
                      :key="item.value"
                      @click="setLVPageSize(item.value)"
                    >
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div></v-col
          >
          <v-col v-if="showRating" cols="4">
            <v-row>
              <v-col>
                <v-data-table
                  :headers="ratingColumns"
                  :items="currentTagType.ratings"
                  :sort-by.sync="listRatingView.sortColumn"
                  :sort-desc.sync="listRatingView.sortDesc"
                  :dense="listRatingView.density == 'dense'"
                  :class="['mt-2', listRatingView.density]"
                  :page.sync="listRatingView.currentPage"
                  hide-default-footer
                  :items-per-page="listRatingView.itemsPerPage"
                  :fixed-header="!!listRatingView.tableHeight"
                  :height="listRatingView.tableHeight"
                >
                  <template v-slot:top>
                    <v-row
                      ><v-col>
                        <v-chip
                          small
                          v-for="(f, fi) in listRatingView.filterList"
                          :key="'f' + fi"
                          close
                          @click:close="removeFilter(f)"
                        >
                          {{ f.title }}</v-chip
                        ></v-col
                      >
                      <!-- <v-col cols="2">
                <v-btn
                  v-if="currentTagType"
                  small
                  color="primary"
                  class="mb-4"
                  @click="addTagValue"
                  >Add new {{ internal_hierarchy_type.level1_name }}</v-btn
                ></v-col
              > -->
                    </v-row>
                  </template>
                  <!-- eslint-disable vue/valid-v-slot -->
                  <!-- eslint-enable vue/valid-v-slot -->
                  <template v-slot:item="{ item }">
                    <tr @click="editTagTypeRating(item)">
                      <td
                        v-for="col in ratingColumns"
                        :key="col.value"
                        :class="!item.active ? 'inactive-row' : ''"
                        :title="!item.active ? 'Rating is inactive' : null"
                      >
                        {{ item[col.value] }}
                        <v-icon
                          v-if="!item.active && col.value === 'name'"
                          color="red"
                          >mdi-emoticon-dead-outline</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-if="valueRating.tag">
              <v-col>
                Rating Description Overrides for <v-chip label color="gray lighten-2">{{ valueRating.tag.value }}</v-chip>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th v-for="col in ratingColumns" :key="col.value">
                          {{ col.text.toUpperCase() }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(r, ri) in valueRating.ratings"
                        :key="'vrr' + ri"
                        @click="editValueRating(r)"
                      >
                        <td v-for="col in ratingColumns" :key="col.value">
                          <template
                            v-if="col.value === 'description' && r.edit"
                          >
                            <v-textarea
                              v-model="r.description"
                              auto-grow
                              hide-details
                              outlined
                              clearable
                              style="font-size: 12px"
                              row-height="5"
                              rows="3" @click.stop=""
                            ></v-textarea
                            ><v-btn
                              small
                              @click.stop="saveRatingDescription(r)"
                              class="ml-2 float-right"
                              >Save</v-btn
                            ><v-btn
                              small
                              @click.stop="cancelRatingDescription(r)"
                              class="float-right"
                              >Cancel</v-btn
                            ></template
                          >
                          <template v-else> {{ r[col.value] }}</template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col></v-row
        >
      </v-card-text>
    </v-card>
    <v-card
      class="mainPanel mt-6"
      v-if="!hierarchiesLoading && view === 'gradings'"
    >
      <v-card-title class="pb-0">
        <v-row
          ><v-col
            >Gradings allow the categorisation of tags at the template part
            level. When the Grading Type is linked to the tempalte part
            attribute, the tags applied will be grouped or coloured by the
            grading values.</v-col
          ></v-row
        >
        <v-row>
          <v-col cols="2">
            <v-select
              :items="tagGradingTypes"
              :required="true"
              label="Tag Grading Type"
              item-text="name"
              item-value="tag_grading_type_id"
              v-model="currentTagGradingType"
              hide-details
              return-object
              dense
              outlined
              append-icon="mdi-plus"
              @click:append="addGradingType"
            ></v-select>
          </v-col>
          <v-col cols="6" v-if="currentTagGradingType">
            <v-btn color="primary" @click="editGradingValue(null)"
              ><v-icon class="pr-4">mdi-tag</v-icon> Add new
              {{ currentTagGradingType.name }} Value</v-btn
            ></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text v-if="currentTagGradingType">
        <v-row>
          <v-col>
            <v-data-table
              :headers="gradingColumns"
              :items="currentTagGradingType.values"
              dense
              :class="['mt-2', listView.density]"
              hide-default-footer
            >
              <!-- eslint-disable vue/valid-v-slot -->
              <template v-slot:item="{ item }">
                <tr @click="editGradingValue(item)">
                  <td
                    v-for="col in gradingColumns"
                    :key="col.value"
                    :style="{
                      backgroundColor:
                        col.value === 'colour' ? item[col.value] : '',
                    }"
                  >
                    {{ item[col.value] }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <TagValueEdit
      :tag="tagForEdit"
      :show="editTagTrigger"
      @saved="tagSaved"
      @cancel="cancelEditTag"
    ></TagValueEdit>
    <v-dialog v-model="addTagTypeDialog.show" persistent width="500">
      <v-card class="pt-5">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>Add New Tag Type</h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              tagTypes.some(
                (x) =>
                  x.tag_type_name.toLowerCase() ===
                  addTagTypeDialog.tag_type_name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Tage Type already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="addTagTypeDialog.tag_type_name"
                label="Tag Type"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="addTagTypeDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveTagType"
            :disabled="
              !addTagTypeDialog.tag_type_name.trim() ||
              tagTypes.some(
                (x) =>
                  x.tag_type_name.toLowerCase() ===
                  addTagTypeDialog.tag_type_name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editTagTypeRatingDialog.show" persistent width="500">
      <v-card class="pt-5" v-if="currentTagType">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>
                {{ editTagTypeRatingDialog.isNew ? "Add New" : "Edit" }} Tag
                Type Rating
              </h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              currentTagType.ratings.some(
                (x) =>
                  x.tag_type_rating_id !==
                    editTagTypeRatingDialog.tag_type_rating_id &&
                  x.name.toLowerCase() ===
                    editTagTypeRatingDialog.name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Tage Type Rating already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editTagTypeRatingDialog.name"
                label="Rating"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editTagTypeRatingDialog.description"
                label="Description"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editTagTypeRatingDialog.sequence"
                label="Rating Number"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="editTagTypeRatingDialog.error_message">
            <v-col>
              <v-alert dense type="error"
                ><v-row
                  ><v-col class="grow">{{
                    editTagTypeRatingDialog.error_message
                  }}</v-col>
                  <v-col class="shrink"
                    ><v-btn @click="changeTagTypeRatingStatus(true)"
                      >Make Inactive Anyway</v-btn
                    ></v-col
                  ></v-row
                ></v-alert
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!editTagTypeRatingDialog.isNew"
            color="blue darken-1"
            text
            @click="changeTagTypeRatingStatus(false)"
            >{{
              editTagTypeRatingDialog.active ? "Make Inactive" : "Make Active"
            }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editTagTypeRatingDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveTagTypeRating"
            :disabled="
              !editTagTypeRatingDialog.name.trim() ||
              currentTagType.ratings.some(
                (x) =>
                  x.tag_type_rating_id !==
                    editTagTypeRatingDialog.tag_type_rating_id &&
                  x.name.toLowerCase() ===
                    editTagTypeRatingDialog.name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addGradingTypeDialog.show" persistent width="500">
      <v-card class="pt-5">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>Add New Tag Grading Type</h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              tagGradingTypes.some(
                (x) =>
                  x.name.toLowerCase() ===
                  addGradingTypeDialog.name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Grading Type already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="addGradingTypeDialog.name"
                label="Tag Grading Type"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="addGradingTypeDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveGradingType"
            :disabled="
              !addGradingTypeDialog.name.trim() ||
              tagGradingTypes.some(
                (x) =>
                  x.name.toLowerCase() ===
                  addGradingTypeDialog.name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editGradingValueDialog.show" persistent width="500">
      <v-card class="pt-5" v-if="currentTagGradingType">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>Add New Tag Grading Type Value</h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              currentTagGradingType.values.some(
                (x) =>
                  x.tag_grading_type_value_id !==
                    editGradingValueDialog.tag_grading_type_value_id &&
                  x.name.toLowerCase() ===
                    editGradingValueDialog.name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Grading Value already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.name"
                label="Value"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.description"
                label="Description"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.sequence"
                label="Position"
                required
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.colour"
                label="Colour"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editGradingValueDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveGradingValue"
            :disabled="
              !editGradingValueDialog.name.trim() ||
              currentTagGradingType.values.some(
                (x) =>
                  x.tag_grading_type_value_id !==
                    editGradingValueDialog.tag_grading_type_value_id &&
                  x.name.toLowerCase() ===
                    editGradingValueDialog.name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
  <script>
import { mapState } from "vuex";
import utils from "@/common/utils.js";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import TagValueEdit from "../document/TagValueEdit.vue";

export default {
  name: "adminHierarchy",
  components: {
    Pagination,
    PageDescription,
    TagValueEdit,
  },
  data: function () {
    return {
      utils: utils,
      currentTagType: null,
      currentTagGradingType: null,
      listColumns: [],
      listData: [],
      filterList: [],
      gradingColumns: [],
      activeFilterCount: 0,
      listView: {
        show: false,
        columnsEnabled: [],
        columns: [],
        filteredItems: [],
        sortColumn: null,
        sortDesc: null,
        pageSize: 0,
        density: "dense",
        currentPage: 1,
        itemsPerPage: 15,
        tableHeight: null,
        hierarchyView: null,
        renderCount: 0,
      },
      ratingColumns: [],
      listRatingView: {
        show: false,
        columnsEnabled: [],
        columns: [],
        filteredItems: [],
        sortColumn: null,
        sortDesc: null,
        pageSize: 0,
        density: "dense",
        currentPage: 1,
        itemsPerPage: 15,
        tableHeight: null,
        hierarchyView: null,
        renderCount: 0,
        filterList: [],
      },
      tagForEdit: null,
      editTagTrigger: 0,
      allowAdd: false,
      allowEditTag: false,
      allowEditClassification: false,
      allowEditCategory: false,
      showRating: false,
      showClassification: false,
      showCategory: false,
      addTagTypeDialog: {
        show: false,
        tag_type_name: "",
      },
      editTagTypeRatingDialog: {
        show: false,
        name: "",
        description: "",
        sequence: "",
        tag_type_rating_id: null,
        active: 1,
        isNew: false,
        error_message: "",
      },
      hideInactive: true,
      addGradingTypeDialog: {
        show: false,
        name: "",
      },
      editGradingValueDialog: {
        show: false,
        name: "",
        description: "",
        sequence: "",
        colour: "",
        tag_grading_type_value_id: null,
        isNew: false,
      },
      view: "tags",
      valueRating: {
        tag: null,
        ratings: [],
      },
    };
  },
  created: function () {
    if (this.tagTypes.length && !this.currentTagType)
      this.currentTagType = this.tagTypes[0];
    if (this.tagGradingTypes.length && !this.currentTagGradingType)
      this.currentTagGradingType = this.tagGradingTypes[0];
    if (this.currentTagType) this.setup();
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagGradingTypes: (state) => state.hierarchies.tagGradingTypes,
      tagClassififcations: (state) => state.hierarchies.tagClassififcations,
      tagCategories: (state) => state.hierarchies.tagCategories,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
    itemsPerPageOptions() {
      return [
        { text: "10 Rows", value: 10 },
        { text: "15 Rows", value: 15 },
        { text: "20 Rows", value: 20 },
        { text: "25 Rows", value: 25 },
        { text: "30 Rows", value: 30 },
        { text: "35 Rows", value: 35 },
        { text: "40 Rows", value: 40 },
      ];
    },
    itemsPerPage() {
      if (this.listView.pageSize !== 0) return this.listView.pageSize;
      else return this.listView.itemsPerPage;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.listView.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
  },
  watch: {
    hierarchiesLoading(val) {
      if (val === false && this.tagTypes.length) {
        this.currentTagType = this.tagTypes[0];
      }
      if (val === false && this.tagGradingTypes.length) {
        this.currentTagGradingType = this.tagGradingTypes[0];
      }
    },
    currentTagType(newVal) {
      if (newVal) this.setup();
    },
    hideInactive() {
      this.filterData();
    },
  },
  methods: {
    setup() {
      this.allowAdd = this.tagPermissions.addTag;
      this.allowEditTag = this.tagPermissions.editTag;
      this.allowEditClassification = this.tagPermissions.editClassification;
      this.allowEditCategory = this.tagPermissions.editCategory;
      this.showClassification = this.$loginState.user.settings.some(
        (s) =>
          s.setting === "tagging_classification_used" && s.value !== "false"
      );
      this.showCategory = this.$loginState.user.settings.some(
        (s) =>
          s.setting === "tagging_categorisation_used" && s.value !== "false"
      );
      this.showRating = this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_rating_used" && s.value !== "false"
      );
      this.listColumns.splice(0, this.listColumns.length);
      this.ratingColumns.splice(0, this.ratingColumns.length);
      this.gradingColumns.splice(0, this.gradingColumns.length);

      this.listColumns.push({
        level: null,
        value: "value",
        text: "Tag Value",
        filterSettings: {
          show: false,
          column: "value",
          values: [],
          active: false,
          searchText: "",
          page: 1,
          pages: 1,
        },
      });

      this.listColumns.push({
        level: null,
        value: "description",
        text: "Tag Description",
        filterSettings: {
          show: false,
          column: "description",
          values: [],
          active: false,
          searchText: "",
          page: 1,
          pages: 1,
        },
      });
      if (this.showClassification)
        this.listColumns.push({
          level: null,
          value: "tag_classififcation_name",
          text: "Classififation",
          filterSettings: {
            show: false,
            column: "tag_classififcation_name",
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          },
        });
      if (this.showCategory)
        this.listColumns.push({
          level: null,
          value: "tag_category_name",
          text: "Category",
          filterSettings: {
            show: false,
            column: "tag_category_name",
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          },
        });
      this.listColumns.forEach((c) => {
        c.filterSettings.values = this.distinctValues(
          this.currentTagType.values,
          [c.value]
        )
          .map((x, xi) => {
            let col = c.value;
            let text =
              c.value === "active"
                ? x.active
                  ? "Active"
                  : "Inactive"
                : x[col];
            if (text === undefined || text === null) {
              text = "";
            }
            let searchText = text;
            return {
              text: text,
              value: c.value === "active" ? x[col] : text,
              selected: false,
              visible: true,
              available: true,
              count: x._count,
              searchValues: [searchText],
              page: parseInt(xi / 7) + 1,
            };
          })
          .sort((a, b) =>
            Number(a.text) > Number(b.text)
              ? 1
              : Number(a.text) < Number(b.text)
              ? -1
              : 0
          );
        c.filterSettings.pages =
          parseInt((c.filterSettings.values.length - 1) / 7) + 1;
      });
      if (this.showRating) {
        this.listColumns.push({
          level: null,
          value: "tag_value_ratings",
          text: "ratings",
          //   filterSettings: {
          //     show: false,
          //     column: "tag_value_ratings",
          //     values: [],
          //     active: false,
          //     searchText: "",
          //     page: 1,
          //     pages: 1,
          //   },
        });
        this.ratingColumns.push({
          level: null,
          value: "name",
          text: "Rating",
          filterSettings: {
            show: false,
            column: "name",
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          },
        });
        this.ratingColumns.push({
          level: null,
          value: "sequence",
          text: "#",
          filterSettings: {
            show: false,
            column: "sequence",
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          },
        });

        this.ratingColumns.push({
          level: null,
          value: "description",
          text: "Rating Description",
          filterSettings: {
            show: false,
            column: "description",
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          },
        });
      }
      this.gradingColumns.push({
        value: "name",
        text: "Grading",
      });
      this.gradingColumns.push({
        value: "sequence",
        text: "Position",
      });
      this.gradingColumns.push({
        value: "description",
        text: "Description",
      });
      this.gradingColumns.push({
        value: "colour",
        text: "Colour",
      });
      this.filterData();
    },
    distinctValues: function (arr, keys) {
      let list = [];
      arr.forEach(function (item) {
        let row = list.filter(function (li) {
          let match = true;
          keys.forEach(function (k) {
            if (li[k] !== item[k]) match = false;
          });
          return match;
        })[0];
        if (!row) {
          row = { _count: 1 };
          keys.forEach(function (k) {
            row[k] = item[k];
          });
          list.push(row);
        } else {
          row._count++;
        }
      });
      return list;
    },
    addTagType() {
      this.addTagTypeDialog.show = true;
    },
    saveTagType() {
      if (this.addTagTypeDialog.tag_type_name.trim().length) {
        let data = {
          entity_type: "tag_type",
          status_change: false,
          tag_type_id: -1,
          tag_type_name: this.addTagTypeDialog.tag_type_name,
        };

        this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
          if (resp.data.Status === "OK") {
            this.currentTagType = this.tagTypes.find(
              (x) => x.tag_type_id === resp.data.Data[0][0].tag_type_id
            );
            this.addTagTypeDialog.show = false;
            this.addTagValue();
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    editTagTypeRating(item) {
      this.editTagTypeRatingDialog.tag_type_rating_id =
        item?.tag_type_rating_id || -1;
      this.editTagTypeRatingDialog.description = item?.description || "";
      this.editTagTypeRatingDialog.name = item?.name || "";
      this.editTagTypeRatingDialog.sequence =
        item?.sequence || this.currentTagType.ratings.length + 1;
      this.editTagTypeRatingDialog.active = item?.active ?? 1;
      this.editTagTypeRatingDialog.error_message = "";
      this.editTagTypeRatingDialog.isNew =
        this.editTagTypeRatingDialog.tag_type_rating_id < 1;
      this.editTagTypeRatingDialog.show = true;
    },
    saveTagTypeRating() {
      if (this.editTagTypeRatingDialog.name.trim().length) {
        let data = {
          entity_type: "tag_type_rating",
          status_change: false,
          tag_type_id: this.currentTagType.tag_type_id,
          tag_type_rating_id: this.editTagTypeRatingDialog.tag_type_rating_id,
          name: this.editTagTypeRatingDialog.name,
          description: this.editTagTypeRatingDialog.description,
          sequence: this.editTagTypeRatingDialog.sequence,
        };

        this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
          if (resp.data.Status === "OK") {
            let respData = resp.data.Data[0][0];
            let currentRating = this.currentTagType.ratings.find(
              (x) => x.tag_type_rating_id === respData.tag_type_rating_id
            );
            if (currentRating) {
              currentRating.name = respData.name;
              currentRating.description = respData.description;
              currentRating.sequence = respData.sequence;
            }
            this.editTagTypeRatingDialog.show = false;
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    changeTagTypeRatingStatus(ignore_validation) {
      let data = {
        entity_type: "tag_type_rating",
        status_change: true,
        tag_type_id: this.currentTagType.tag_type_id,
        tag_type_rating_id: this.editTagTypeRatingDialog.tag_type_rating_id,
        active: this.editTagTypeRatingDialog.active === 1 ? 0 : 1,
        ignore_validation: ignore_validation ? 1 : 0,
      };
      this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
        if (
          resp.data.Status === "OK" &&
          resp.data.Data.length &&
          resp.data.Data[0].length
        ) {
          let respData = resp.data.Data[0][0];
          let currentRating = this.currentTagType.ratings.find(
            (x) => x.tag_type_rating_id === respData.tag_type_rating_id
          );
          if (Number(respData.valid) === 1) {
            currentRating.active = respData.active;
            this.editTagTypeRatingDialog.show = false;
          } else {
            this.editTagTypeRatingDialog.error_message = `This rating is currently applied to ${
              respData.used_count
            } document${Number(respData.used_count) === 1 ? "" : "s"}.`;
          }
        }
        this.response = resp.data;
        this.isLoading = false;
      }),
        (error) => {
          console.error(error);
          this.isLoading = false;
        };
    },
    editTag(tag) {
      this.tagForEdit = tag;
      this.editTagTrigger++;
    },
    addTagValue() {
      this.tagForEdit = {
        tag_value_id: -1,
        value: "",
        tag_type_id: this.currentTagType.tag_type_id,
      };
      this.editTagTrigger++;
    },
    cancelEditTag() {
      this.tagForEdit = null;
    },
    tagSaved(updated) {
      let tag = this.listData.find(
        (x) => x.tag_value_id === updated.tag_value_id
      );
      if (tag) {
        tag.value = updated.value;
        tag.active = updated.active;
      } else {
        this.filterData();
      }
      this.tagForEdit = null;
    },
    openlistViewFilter(filterSettings) {
      if (!filterSettings.show) {
        filterSettings.values
          .filter((v) => v.selected && !v.applied)
          .forEach((v) => (v.selected = false));
        filterSettings.values
          .filter((v) => !v.selected && v.applied)
          .forEach((v) => (v.selected = true));
        return;
      }
      filterSettings.values
        .filter((v) => !v.selected && v.applied)
        .forEach((v) => (v.applied = false));
      if (filterSettings.mostRecent) return;
      let usedValues = this.distinctValues(this.listData, [
        filterSettings.column,
      ]).map((x) => x[filterSettings.column]);
      let valueCount = 0;
      if (
        usedValues.length === filterSettings.values.length ||
        (this.activeFilterCount === 1 && filterSettings.isActive)
      ) {
        valueCount = filterSettings.values.length;
        filterSettings.values.forEach((x, xi) => {
          x.visible = true;
          x.page = parseInt(xi / 7) + 1;
        });
      } else {
        filterSettings.values.forEach((v) => {
          v.visible = usedValues.some((uv) => uv === v.text);
          v.page = v.visible ? parseInt(valueCount / 7) + 1 : 0;
          if (v.visible) valueCount++;
        });
      }
      filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    removeFilter(f) {
      let lvfilter = this.listColumns.find((x) => x.value === f.column);
      if (lvfilter) {
        lvfilter.filterSettings.values.forEach((x) => {
          x.selected = false;
          x.applied = false;
        });
        lvfilter.filterSettings.isActive = false;
        this.filterData();
      }
    },
    filterScroll(data, filterSettings) {
      if (data) {
        let page = filterSettings.page + (data.deltaY >= 0 ? 1 : -1);
        if (page > filterSettings.pages || page < 1) return;
        filterSettings.page = page;
      }
    },
    setFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values.forEach((x) => {
          x.selected = x.value === value;
        });
        this.filterHeading(column.filterSettings);
      }
    },
    removeFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values
          .filter((x) => x.value === value)
          .forEach((x) => (x.selected = false));
        this.filterHeading(column.filterSettings);
      }
    },
    filterHeading(filterSettings) {
      filterSettings.values
        .filter((v) => v.selected)
        .forEach((v) => (v.applied = true));
      filterSettings.isActive = filterSettings.values.some((x) => x.selected);
      filterSettings.show = false;
      let activeCount = 0;
      this.listColumns
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          c.filterSettings.mostRecent = false;
          if (c.filterSettings.isActive) activeCount++;
        });
      filterSettings.mostRecent = filterSettings.isActive;
      this.activeFilterCount = activeCount;
      this.filterData();
    },
    filterSearchAll(filterSettings) {
      if (filterSettings.searchText) {
        let newVal = !filterSettings.values.every(
          (x) => !x.visible || x.selected
        );
        filterSettings.values.forEach((x) => {
          if (x.visible || !newVal) x.selected = newVal;
        });
      } else {
        let newVal = !filterSettings.values.every((x) => x.selected);
        filterSettings.values.forEach((x) => {
          x.selected = newVal;
        });
      }
    },
    filterSearch(filterSettings) {
      let index = 0;
      let search = (filterSettings.searchText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      filterSettings.values.forEach((x) => {
        const visible =
          !search.length ||
          search.every((s) => x.searchValues.some((v) => v.indexOf(s) >= 0));
        x.visible = visible;
        if (visible) {
          x.page = parseInt(index / 7) + 1;
          index++;
        } else {
          x.page = 0;
        }
      });
      filterSettings.pages = parseInt((index - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    filterClear(filterSettings) {
      filterSettings.searchText = "";
      filterSettings.values.forEach((x, xi) => {
        x.visible = true;
        x.selected = false;
        x.applied = false;
        x.page = parseInt(xi / 7) + 1;
      });
      filterSettings.pages =
        parseInt((filterSettings.values.length - 1) / 7) + 1;
      this.filterHeading(filterSettings);
    },
    filterData() {
      let filters = this.listColumns
        .filter((c) => c.filterSettings?.isActive)
        .map((c) => {
          return {
            name: c.text,
            column: c.filterSettings.column,
            values: c.filterSettings.values
              .filter((v) => v.selected)
              .map((v) => v.value),
          };
        });
      let raw = this.currentTagType.values;
      if (this.hideInactive) {
        raw = raw.filter((x) => x.active);
      }
      let data = !filters.length
        ? raw
        : raw.filter((x) => {
            let res = true;
            if (filters.length)
              res = filters.every((f) =>
                f.values.some((v) => x[f.column] === v)
              );
            return res;
          });
      this.listData = data;
      this.filterList = filters.map((f) => {
        return {
          title: `${f.name} = [${f.values.join(", ")}]`,
          name: f.name,
          column: f.column,
        };
      });
    },
    setLVPageSize(value) {
      if (this.listView.pageSize === 0) {
        localStorage.setItem("adTAG", value);
        this.listView.itemsPerPage = value;
      }
    },
    exportLVToCSV() {
      let cols = this.listColumns.map((x) => {
        return {
          text: x.text,
          value: x.value,
        };
      });
      let data = cols.map((h) => '"' + h.text + '"').join(",");
      data += "\n";

      let raw = this.currentTagType.values || [];
      raw.forEach((d) => {
        data += cols
          .map((h) =>
            utils.csvEscape(utils.removeTags(d[h.value]).replace(/\n/g, "|"))
          )
          .join(",");
        data += "\n";
      });

      utils.downloadFile(
        data,
        `Tags - ${this.currentTagType.tag_type_name}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
    addGradingType() {
      this.addGradingTypeDialog.show = true;
    },
    saveGradingType() {
      if (this.addGradingTypeDialog.name.trim().length) {
        let data = {
          transaction: "TagGradingType",
          tag_grading_type_id: -1,
          name: this.addGradingTypeDialog.name,
          description: this.addGradingTypeDialog.description,
        };

        this.$store.dispatch("hierarchies/saveTagData", data).then((resp) => {
          if (resp.data.Status === "OK") {
            this.currentTagGradingType = this.tagGradingTypes.find(
              (x) =>
                x.tag_grading_type_id ===
                resp.data.Data[0][0].tag_grading_type_id
            );
            this.addGradingTypeDialog.show = false;
            this.editGradingValue();
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    editGradingValue(value) {
      this.editGradingValueDialog.name = value?.name ?? "";
      this.editGradingValueDialog.description = value?.description ?? "";
      this.editGradingValueDialog.colour = value?.colour ?? "";
      this.editGradingValueDialog.tag_grading_type_value_id =
        value?.tag_grading_type_value_id ?? -1;
      this.editGradingValueDialog.sequence = value?.sequence
        ? value.sequence
        : this.currentTagGradingType.values
        ? this.currentTagGradingType.values.reduce(
            (p, c) => (c.sequence > p ? c.sequence + 1 : p),
            1
          )
        : 1;
      this.editGradingValueDialog.show = true;
    },
    cancelGradingValueEdit() {
      this.editGradingValueDialog.show = false;
    },
    saveGradingValue() {
      if (this.editGradingValueDialog.name.trim().length) {
        let data = {
          transaction: "TagGradingTypeValue",
          tag_grading_type_id: this.currentTagGradingType.tag_grading_type_id,
          tag_grading_type_value_id:
            this.editGradingValueDialog.tag_grading_type_value_id,
          name: this.editGradingValueDialog.name,
          description: this.editGradingValueDialog.description,
          sequence: this.editGradingValueDialog.sequence,
          colour: this.editGradingValueDialog.colour,
        };

        this.$store.dispatch("hierarchies/saveTagData", data).then((resp) => {
          if (resp.data.Status === "OK") {
            const respData = resp.data.Data[0][0];
            let value = this.currentTagGradingType.values.find(
              (x) =>
                x.tag_grading_type_value_id ===
                respData.tag_grading_type_value_id
            );
            if (value) {
              value.name = respData.name;
              value.description = respData.description;
              value.sequence = respData.sequence;
            }
            this.editGradingValueDialog.show = false;
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    showValueRatings(tag) {
      this.valueRating.tag = tag;
      this.valueRating.ratings = this.currentTagType.ratings.map((r) => {
        let override = tag.ratings.find(
          (x) => x.tag_type_rating_id === r.tag_type_rating_id
        );
        return {
          tag_value_rating_id: override?.tag_value_rating_id || null,
          tag_type_rating_id: r.tag_type_rating_id,
          tag_value_id: tag.tag_value_id,
          name: r.name,
          description: override?.description || r.description,
          sequence: r.sequence,
          active: r.active,
          edit: false,
        };
      });
    },
    editValueRating(rating) {
      if (rating.edit) this.cancelRatingDescription(rating);
      else {
        rating.edit = true;
        rating.original = rating.description;
      }
    },
    saveRatingDescription(rating) {
      rating.entity_type = "tag_value_rating";

      this.$store.dispatch("hierarchies/saveItem", rating).then((resp) => {
        if (resp.data.Status === "OK") {
          let respData = resp.data.Data[0][0];
          let currentRating = this.valueRating.tag.ratings.find(
            (x) => x.tag_type_rating_id === respData.tag_type_rating_id
          );
          if (currentRating) {
            currentRating.description = respData.description;
          }
          rating.edit = false;
        }
        this.response = resp.data;
      }),
        (error) => {
          console.error(error);
          this.isLoading = false;
        };
    },
    cancelRatingDescription(rating) {
      rating.edit = false;
      rating.description = rating.original;
    },
  },
};
</script>
  
  <style scoped lang="scss">
::v-deep .v-data-table tbody td {
  cursor: pointer !important;
}
.inactive-row {
  background-color: #ebe8e8;
}
</style>
<template>
  <div
    :class="{
      'vue-treeselect-container v-input roundish v-text-field--enclosed v-text-field--outlined v-text-field v-select': true,
      'v-input--is-focused primary--text': hasFocus,
      'dense v-input--dense': dense,
      borderless: borderless,
      'error--text v-input--has-state': error && !addLeafNode.show,
    }"
  >
    <div class="v-input__control" v-if="!addLeafNode.show">
      <div
        role="button"
        aria-haspopup="listbox"
        class="v-input__slot"
        :aria-owns="id"
      >
        <fieldset aria-hidden="true">
          <legend :style="`width: ${labelWidth};`">
            <span class="notranslate">&ZeroWidthSpace;</span>
          </legend>
        </fieldset>
      </div>

      <div class="v-select__slot vue-treeselect-slot">
        <label
          :for="id"
          :class="[
            'v-label vue-treeselect-label',
            hasFocus || pickerValue ? 'has-focus' : '',
          ]"
          >{{ label }}</label
        >
        <div class="v-select__selections">
          <treeselect
            :value="pickerValue"
            :clearable="clearable"
            :disabled="readonly"
            :show-count="false"
            :disableBranchNodes="!fulldetail"
            :options="localOptions"
            :placeholder="placeholder"
            :name="id"
            :searchNested="true"
            :startSearchLength="5"
            :waitSearchFinishTime="500"
            :disableFuzzyMatching="true"
            :multiple="multiple"
            :limit="limit"
            :class="{
              dense: dense,
              'has-focus': hasFocus,
              'has-value': !!pickerValue,
              'validation-error': error,
              borderless: borderless,
            }"
            @input="updateValue($event)"
            @open="gainedFocus"
            :autoFocus="autofocus"
            :openOnFocus="autofocus"
            appendToBody
            :flat="returnFullDetail"
            ref="treeselect"
          >
            <!-- @close="lostFocus" -->
            <label
              slot="option-label"
              slot-scope="{
                node,
                shouldShowCount,
                count,
                labelClassName,
                countClassName,
              }"
              :class="labelClassName"
              :title="node.label"
            >
              <v-icon v-if="!node.isBranch && node.raw.isAddNode"
                >mdi-plus-circle</v-icon
              >
              {{ node.raw.isAddNode ? "Add new title" : node.label }}
              <span v-if="shouldShowCount" :class="countClassName"
                >({{ count }})</span
              >
            </label>
          </treeselect>
        </div>
      </div>
    </div>
    <div
      v-show="error && hint && !addLeafNode.show"
      class="vue-treeselect-hint"
    >
      {{ hint }}
    </div>
    <AdminHierarchyNodeDetail
      :node="addLeafNode.parent"
      mode="add"
      :show="addLeafNode.show"
      @close="cancelAddLeafNode"
      @saved="nodeAdded"
    >
    </AdminHierarchyNodeDetail>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import utils from "../common/utils";
import AdminHierarchyNodeDetail from "@/components/hierarchy/cAdminHierarchyNodeDetail";

export default {
  name: "HierarchyTreePicker",
  components: {
    Treeselect,
    AdminHierarchyNodeDetail,
  },
  props: {
    value: {},
    placeholder: { type: String },
    label: { type: String },
    hint: { type: String },
    dense: { type: Boolean },
    error: { type: Boolean },
    options: [],
    fulldetail: { type: Boolean },
    textValue: { type: String },
    readonly: { type: Boolean },
    clearable: { type: Boolean },
    hierarchyType: { type: Object },
    init: { type: Boolean },
    multiple: { type: Boolean },
    limit: { type: Number },
    autofocus: { type: Boolean },
    borderless: { type: Boolean },
    returnFullDetail: { type: Boolean }
  },
  data: function () {
    return {
      pickerValue: this.value,
      originalValue: this.value,
      hasFocus: false,
      localOptions: [],
      initialised: false,
      addLeafNode: {
        show: false,
        label: null,
        parent: null,
        value: null,
      },
      id: "treeselect_" + utils.makeid(8),
    };
  },
  created() {
    this.localOptions = this.options;
    this.initialiseAddNode();
    this.expandSelected();
    this.$nextTick(() => {
      const input = this.$refs.treeselect?.$el.querySelector(
        "input.vue-treeselect__input"
      );
      if (input) {
        input.id = this.id;
        input.addEventListener("focus", this.gainedFocus);
        input.addEventListener("blur", this.lostFocus);
      }
    });
  },
  updated() {
    if (!this.hierarchyType || !this.hierarchyType.allowAddLeafNode) {
      this.addLeafNode.show = false;
      this.addLeafNode.value = null;
    }
  },
  computed: {
    labelWidth() {
      if (this.label && (this.hasFocus || this.pickerValue))
        return `${this.label.length * 7}px`;
      else return "0";
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.initialised) {
          this.pickerValue = val;
        }
      },
    },
    options(val) {
      this.localOptions = val;
    },
    "hierarchyType.allowAddLeafNode"() {
      this.initialiseAddNode();
    },
    init() {
      this.initialiseAddNode();
    },
    pickerValue() {
      this.expandSelected();
    },
  },
  methods: {
    updateValue(value) {
      let newValue = value;
      if (this.hierarchyType?.allowAddLeafNode) {
        this.addLeafNode.show = false;
        if (value < 0 && value > -999) {
          this.addLeafNode.show = true;
          let selNode = this.getNode("id", value);
          let parentNode = this.getTreeNode("node_id", selNode.node_id_parent);
          this.addLeafNode.label = `New ${
            this.hierarchyType["level" + this.hierarchyType.linklevel + "_name"]
          }`;
          newValue = this.originalValue;
          this.addLeafNode.parent = {
            parentOption: parentNode,
            hierarchy_node_id: selNode.node_id_parent,
            ht_id: this.hierarchyType.ht_id,
            level: this.hierarchyType.linklevel - 1,
            linklevel: this.hierarchyType.linklevel,
            nextLevel:
              this.hierarchyType[`level${this.hierarchyType.linklevel}_name`],
            level_name:
              this.hierarchyType[
                `level${this.hierarchyType.linklevel - 1}_name`
              ],
          };
          return;
        }
      }
      if (this.fulldetail) {
        const selNodeIds = [];
        (this.multiple ? newValue : [newValue]).forEach((v) => {
          const selNode = this.getTreeNode("id", v);
          if (selNode && selNode.node_id) this.returnFullDetail ? selNodeIds.push(selNode) : selNodeIds.push(selNode.node_id);
        });
        this.$emit("changeNodeIds", selNodeIds);
      }

      this.$emit("input", newValue);
      this.$emit("change", newValue);
    },
    checkForUnclassified(val) {
      let checkExists = function (options, id) {
        let exists = false;
        options.forEach((x) => {
          if (!exists) {
            if (x.children) {
              exists = checkExists(x.children, id);
            } else {
              exists = x.id === id;
            }
          }
        });
        return exists;
      };
      let that = this;
      let insertUnclassified = function (options, v) {
        if (v && !checkExists(options, v)) {
          options.push({
            id: `Unclassified${v}`,
            label: "Unclassified",
            children: [
              { id: v, label: `${that.textValue || v} [Unclassified]` },
            ],
          });
        }
      };
      if (Array.isArray(val)) {
        val.forEach((v) => insertUnclassified(this.localOptions, v));
      } else {
        insertUnclassified(this.localOptions, val);
      }
    },
    initialiseAddNode(checkPrevious) {
      if (checkPrevious && this.initialised) return;
      this.addLeafNode.show = false;
      this.addLeafNode.value = null;
      let hasAdd = !!this.getNode("isAddNode", true);
      if (this.hierarchyType?.allowAddLeafNode && !hasAdd) {
        let count = 0;
        let addNode = (nodes, parent) => {
          let isEnd = false;
          nodes.forEach((n) => {
            if (n.children) {
              addNode(n.children, n);
            } else if (!n.isActionTrigger) {
              isEnd = true;
            }
          });
          if (isEnd) {
            count--;
            nodes.unshift({
              id: count,
              label: " " + parent?.label,
              isAddNode: true,
              node_id_parent: parent?.node_id,
            });
          }
        };
        addNode(this.localOptions);
      } else if (hasAdd) {
        let remAdd = (nodes) => {
          let index = nodes.findIndex((x) => x.isAddNode);
          if (index >= 0) {
            nodes.splice(index, 1);
          }
          nodes.forEach((n) => {
            if (n.children) {
              remAdd(n.children);
            }
          });
        };
        remAdd(this.localOptions);
      }
      if (!this.multiple) this.checkForUnclassified(this.pickerValue);
      this.initialised = true;
    },
    expandSelected() {
      const node = this.getTreeNode("id", this.pickerValue);
      if (node && node.parents && node.parents.length) {
        let opts = this.localOptions;
        node.parents.forEach((p) => {
          const parent = opts.find((o) => o.name === p);
          if (parent) {
            parent.isDefaultExpanded = true;
            opts = parent.children;
          }
        });
      }
    },
    nodeAdded(details) {
      if (!details.newNode) return;
      details.newNode.ht_name = this.hierarchyType.label.replace("Select ", "");
      let newOption = {
        id: details.newNode.hierarchy_node.hr_id,
        label: details.newNode.hierarchy_node.name,
        name: details.newNode.hierarchy_node.name,
        node_id: details.newNode.hierarchy_node.hierarchy_node_id,
      };
      if (this.addLeafNode.parent.parentOption)
        this.addLeafNode.parent.parentOption.children.unshift(newOption);
      else this.localOptions.unshift(newOption);
      this.pickerValue = newOption.id;
      this.addLeafNode.show = false;
      this.$emit("nodeAdded", details.newNode);
    },
    getNode(property, value) {
      let _getNode = function (options, property, value) {
        let exists = null;
        options.forEach((x) => {
          if (!exists) {
            if (x.children) {
              exists = _getNode(x.children, property, value);
              if (exists) exists.parents.unshift(x.name);
            } else {
              exists = x[property] === value ? x : null;
              if (exists) exists.parents = [];
            }
          } else {
            return;
          }
        });
        return exists;
      };
      return value ? _getNode(this.localOptions, property, value) : null;
    },
    getTreeNode(property, value) {
      let _getNode = function (options, property, value) {
        let exists = null;
        options.forEach((x) => {
          if (!exists) {
            exists = x[property] === value ? x : null;
            if (exists) exists.parents = [];
            else if (x.children) {
              exists = _getNode(x.children, property, value);
              if (exists) exists.parents.unshift(x.name);
            }
          } else {
            return;
          }
        });
        return exists;
      };
      return value ? _getNode(this.localOptions, property, value) : null;
    },
    cancelAddLeafNode() {
      this.addLeafNode.value = null;
      this.addLeafNode.show = false;
      this.lostFocus();
    },
    gainedFocus() {
      if (this.$vuetify.theme.isDark)
        document.querySelectorAll('.vue-treeselect__menu-container').forEach(e => e.classList.add('theme--dark'));
      else
        document.querySelectorAll('.vue-treeselect__menu-container').forEach(e => e.classList.remove('theme--dark'));

      this.hasFocus = true;
      this.$emit("focus");
    },
    lostFocus() {
      if (this.addLeafNode.show) return;
      this.hasFocus = false;
      this.$emit("lostFocus");
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/vars";

.vue-treeselect-container.borderless {
  .v-input__slot,
  .vue-treeselect-label {
    display: none;
  }
}

.vue-treeselect-label {
  position: absolute;
  right: auto;
  left: 18px;
  top: 30px !important;

  &.has-focus {
    left: 12px;
    top: 2px !important;
    font-size: 12px !important;
  }
}

.vue-treeselect-slot {
  left: 2px;
  right: auto;
  top: -10px;
  position: absolute !important;
}

.vue-treeselect-hint {
  position: absolute;
  left: 5px;
  top: 50px;
  font-size: 12px;
  margin: 5px 12px;
}

.dense {
  .vue-treeselect-label {
    left: 13px;
    top: 21px !important;
    &.has-focus {
      left: 12px;
      top: 2px !important;
    }
  }
  .vue-treeselect-hint {
    top: 32px;
  }
}

.vue-treeselect-hint-addLeafNode {
  position: relative;
  left: 5px;
  top: 0px;
  font-size: 12px;
  margin: 5px 12px;
}

.vue-treeselect {
  font-family: "Martel Sans", sans-serif;
  color: $input-text-color;
  margin-top: 3px;
  .vue-treeselect__menu-container {
    margin-top: 1px;
  }

  .vue-treeselect__control {
    border-radius: 14px;
    margin-right: 10px;
    border: 0;
    height: 56px;
    font-size: 1rem;
  }

  .vue-treeselect__single-value,
  .vue-treeselect__value-container,
  .vue-treeselect__placeholder {
    padding: 12px 0 10px 12px;
    height: 44px;
  }

  .vue-treeselect__input-container {
    padding: 0 0 10px 0;
    height: 34px;
  }

  .vue-treeselect__control-arrow-container {
    padding: 0 28px 0 0;
  }

  &:not(.has-focus) .vue-treeselect__placeholder,
  &.has-value .vue-treeselect__placeholder {
    display: none;
  }

  &.dense {
    margin-top: 0;
    .vue-treeselect__control {
      height: 35px !important;
    }
    .vue-treeselect__single-value,
    .vue-treeselect__value-container,
    .vue-treeselect__placeholder {
      padding: 8px 0 2px 8px;
      height: 34px;
    }

    .vue-treeselect__input-container {
      padding: 0 0 14px 0;
      height: 34px;
    }

    .vue-treeselect__x-container {
      padding-top: 6px;
    }

    .vue-treeselect__control-arrow-container {
      padding: 6px 28px 0 0;
    }
  }

  &.borderless {
    .vue-treeselect__control {
      border-width: 0 0 1px 0;
      border-radius: 0;
      height: 26px;
    }
    &.has-focus .vue-treeselect__control {
      border-width: 0 0 2px 0 !important;
    }
    .vue-treeselect__single-value,
    .vue-treeselect__value-container,
    .vue-treeselect__placeholder {
      padding: 0;
      font-size: 14px;
    }
  }

  &.validation-error {
    .vue-treeselect__control {
      border-color: #ff5252;
      caret-color: #ff5252;
      border-width: 2px;
    }
    .vue-treeselect__single-value,
    .vue-treeselect__value-container,
    .vue-treeselect__placeholder,
    .vue-treeselect__control-arrow {
      color: #ff5252;
    }
  }
}
.hierarchypicker-hierarchy-level {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 5px;
  //   font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hierarchypicker-container {
  border: rgb(221, 221, 221) solid 1px;
  border-radius: 14px;
  padding: 10px;
  width: 100%;
}
.hierarchypicker-container-heading {
  margin-top: -20px;
  font-size: 12px;
  margin-bottom: 7px;
  background-color: white;
  display: table;
}

.vue-treeselect__menu-container.theme--dark {
  .vue-treeselect__menu {
    background-color: $primary-background-dark;
    border-color: $secondary-background-dark;

    .vue-treeselect__option--highlight {
      background-color: $secondary-background-dark;
      color: white;
      .vue-treeselect__option-arrow {
        color: white;
      }
    }
  }
}

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'vue-treeselect-container v-input roundish v-text-field--enclosed v-text-field--outlined v-text-field v-select': true,
    'v-input--is-focused primary--text': _vm.hasFocus,
    'dense v-input--dense': _vm.dense,
    borderless: _vm.borderless,
    'error--text v-input--has-state': _vm.error && !_vm.addLeafNode.show,
  }},[(!_vm.addLeafNode.show)?_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot",attrs:{"role":"button","aria-haspopup":"listbox","aria-owns":_vm.id}},[_c('fieldset',{attrs:{"aria-hidden":"true"}},[_c('legend',{style:(`width: ${_vm.labelWidth};`)},[_c('span',{staticClass:"notranslate"},[_vm._v("​")])])])]),_c('div',{staticClass:"v-select__slot vue-treeselect-slot"},[_c('label',{class:[
          'v-label vue-treeselect-label',
          _vm.hasFocus || _vm.pickerValue ? 'has-focus' : '',
        ],attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"v-select__selections"},[_c('treeselect',{ref:"treeselect",class:{
            dense: _vm.dense,
            'has-focus': _vm.hasFocus,
            'has-value': !!_vm.pickerValue,
            'validation-error': _vm.error,
            borderless: _vm.borderless,
          },attrs:{"value":_vm.pickerValue,"clearable":_vm.clearable,"disabled":_vm.readonly,"show-count":false,"disableBranchNodes":!_vm.fulldetail,"options":_vm.localOptions,"placeholder":_vm.placeholder,"name":_vm.id,"searchNested":true,"startSearchLength":5,"waitSearchFinishTime":500,"disableFuzzyMatching":true,"multiple":_vm.multiple,"limit":_vm.limit,"autoFocus":_vm.autofocus,"openOnFocus":_vm.autofocus,"appendToBody":"","flat":_vm.returnFullDetail},on:{"input":function($event){return _vm.updateValue($event)},"open":_vm.gainedFocus},scopedSlots:_vm._u([{key:"option-label",fn:function({
              node,
              shouldShowCount,
              count,
              labelClassName,
              countClassName,
            }){return _c('label',{class:labelClassName,attrs:{"title":node.label}},[(!node.isBranch && node.raw.isAddNode)?_c('v-icon',[_vm._v("mdi-plus-circle")]):_vm._e(),_vm._v(" "+_vm._s(node.raw.isAddNode ? "Add new title" : node.label)+" "),(shouldShowCount)?_c('span',{class:countClassName},[_vm._v("("+_vm._s(count)+")")]):_vm._e()],1)}}],null,false,1616425247)})],1)])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error && _vm.hint && !_vm.addLeafNode.show),expression:"error && hint && !addLeafNode.show"}],staticClass:"vue-treeselect-hint"},[_vm._v(" "+_vm._s(_vm.hint)+" ")]),_c('AdminHierarchyNodeDetail',{attrs:{"node":_vm.addLeafNode.parent,"mode":"add","show":_vm.addLeafNode.show},on:{"close":_vm.cancelAddLeafNode,"saved":_vm.nodeAdded}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

<script setup>
//#region Imports
import { ref, onBeforeMount, onUpdated, watch, reactive, getCurrentInstance, computed } from 'vue';
import { inject } from 'vue';
import axios from 'axios';
import Notification from "@/components/common/SnackBar.vue";
//#endregion

//#region Reactive
const loginState = inject('loginState');
const store = inject('store');

const skillAdminTab = ref(0);

const skillAdminData = reactive({
    loading: true,
    data: [],
    framework: null,
    frameworkItems: [],
    frameworkData: [],
    headers: [
      { text: 'Skill', value: 'name' },
      { text: 'Core Category', value: 'category' },
      { text: 'Type', value: 'type', width:"250px" },
      { text: 'Active', value: 'active', sortable: false, width:"150px" },
      { text: '', value: 'actions', sortable: false, width:"50px" }
    ],
    notification: {
      text: "",
      type: "success"
    },
    search: ''
})
//#endregion

//#region Computed
const itemTypes = computed(() => {
  return [...new Set(skillAdminData.frameworkItems.map(x => { return x.type }))]
});

const gradingType = computed(() => {
  let item = skillAdminData.frameworkData.tagGradingTypes.find(x => x.tag_grading_type_id === skillAdminData.framework.tag_grading_type_id);
  if(!item) return [];
  return item.values;
})
//#endregion



//#region Methods

function getFramework(){
  skillAdminData.skillLoading = true;
  axios.get("skills/framework")
  .then((resp) => {
    skillAdminData.framework = resp.data.framework;
    skillAdminData.frameworkItems = resp.data.items;
    skillAdminData.frameworkData = resp.data.tag_data;
  })
  .catch(err => {
      console.log(err);
      skillAdminData.skillLoading = false;
  });
}

function getAllSkills() {
  skillAdminData.skillLoading = true;
    axios.get("skills/getAllSkills/")
    .then((resp) => {
      skillAdminData.data = resp.data.skill_data;
    })
    .catch(err => {
        console.log(err);
        skillAdminData.skillLoading = false;
    });
}

function getInstance() {
  getFramework();
  getAllSkills();
}

function triggerNotification(msg, type){
  skillAdminData.notification = {
    text: msg,
    type: type
  };
}

function toggleSkill(e, item){
  let data = {
    ...item,
    active: e
  }
  axios.post("skills/frameworkSkill", data)
  .then((resp) => {
    triggerNotification("Skill Updated Successfully","success");
  })
  .catch(err => {
    triggerNotification(err,"error");
  });  
}

function edit(item){
  console.log(item);
}

function changeCategory(e, item){
  console.log(e, item);
}

//#endregion



//#region Component
onUpdated(() => {

})

onBeforeMount(() => {
    getInstance();
    //watch(() => store.state.docs.loading, () => docLoaded());
})
//#endregion
</script>
<template>
  <div>


    <v-tabs style="padding-top:15px; width:50%" v-model="skillAdminTab" grow color="#48A401" background-color="transparent">
      <v-tab class="tab ma-0 pa-0">
        Skill Framework Items
      </v-tab>
      <v-tab class="tab ma-0 pa-0">
        Skill Framework Mappings
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="skillAdminTab" style="">
      <v-tab-item class="tabItem" style="padding-top:15px">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
            v-model="skillAdminData.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="skillAdminData.headers"
              :items="skillAdminData.frameworkItems"
              :search="skillAdminData.search"
            >
            <!-- eslint-disable vue/valid-v-slot -->
            <template v-slot:item.active="{ item }">
              <v-switch dense v-model="item.active" @change="toggleSkill($event, item)"></v-switch>
            </template>
            <template v-slot:item.category="{ item }">
              <div style="width:250px;">
                <v-select dense :items="gradingType" v-model="item.category" item-text="name" return-object @change="changeCategory($event, item)"></v-select>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon @click="edit(item)">mdi-cog</v-icon>
            </template>        
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="tabItem">
        Skill Framework Mappings
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  External Type
                </th>
                <th class="text-left">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in itemTypes"
                :key="idx"
              >
                <td>{{ item }}</td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>   
    </v-tabs-items>   

  

    <Notification :notification="skillAdminData.notification" />
  </div>
</template>
<style lang="scss" scoped>

</style>

<script setup>
//#region Imports
import { ref, onBeforeMount, onUpdated, watch, reactive, getCurrentInstance, computed, nextTick  } from 'vue';
import axios from 'axios';
import { inject } from 'vue';
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import Notification from "@/components/common/SnackBar.vue";
import draggable from "vuedraggable";
//#endregion

//#region Reactive
const loginState = inject('loginState');
const store = inject('store');
const skillManagerState = reactive({
    loading: true,
    reviewGroups: [],
    activeReviewGroup: null,
    editReviewGroup: null,
    framework: null,
    data: [],
    filteredData: [],
    tabs: [
      "Skills Dashboard",
      "Skills Workspace",
      "Skills Framework Admin",
      "Market Insights"
    ],
    headers: [
          {
            text: 'Skill Name',
            value: 'name',
          },
          {
            text: 'Type',
            value: 'type',
          },
          {
            text: 'Client Override Name',
            value: 'client_name',
          },
          {
            text: 'Usage Count',
            value: 'id_count',
          }, 
          { 
            text: 'Actions', 
            value: 'actions', 
            sortable: false
          }
    ],
    skillLoading: true,
    hierarchies: [],
    activeHierarchy: null,
    filteredHierarchies: [],
    selectedSkillTag: null,
    selectedSkillCategories: [],
    notification: {
      text: "",
      type: "success"
    },
    extractSkillsDialog: {
      show: false,
      data: [],
      selected: [],
      loading: false
    },
    addSkillsDialog: {
      show: false,
      data: [],
      selected: []
    },
    createNewReviewGroupNodeId: null,
    existingHrNodes: [],
    activeView: "skill_proficiency",
    nodeHrs: [],
    reviewGroupLinks: [],
    nodeHrsSelected: [],
    addNewHrLinks: true,
    newHrGroup: {
      name: "",
      data: [],
      available: [],
      framework_review_link_id: -1,
      hrs: []
    }
});

let rerender = ref(0);
//#endregion
 
//#region Trash
/*const useClassification = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_classification_used' && s.value === "true"
))

const useCategorisation = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_categorisation_used' && s.value === "true"
))

const useRatings = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_rating_used' && s.value === "true"
))*/
//#endregion

//#region Computed
const filteredData = computed(() => {
  return skillManagerState.data.filter(x => skillManagerState.selectedSkillCategories.includes(x.type) || !x.type)
});

const filteredHierarchyGroupData = computed(() => {
  let returnData = skillManagerState.reviewGroupLinks.map(x => {
    //let filteredData = x.data.filter(d => skillManagerState.selectedSkillCategories.includes(d.type) || !d.type);
    let filteredAvailable = x.available.filter(d => skillManagerState.selectedSkillCategories.includes(d.type) || !d.type);

    return {
      ...x,
      //data: filteredData,
      available: filteredAvailable
    }
  });

  return returnData;
})

const getModelValue = (skill, hr) => computed(() => {
  const record = hr.skills_data.find((sd) => sd.tag_value_id === skill.tag_value_id);
  return record ? record.tag_rating_id : null;
});
//#endregion

//#region Methods

const fallbackRecord = () => {
  return {
    framework_data_id: null,
    framework_id: skillManagerState.framework?.framework_id,
    framework_review_id: skillManagerState.activeReviewGroup,
    skill_id: null,
    tag_rating_id: null,
    hr_node_id: null,
    active: 1
  }
};

const skillExists = (skill, hr) => {
  return hr.skills_data?.map(x => x.tag_value_id).includes(skill.tag_value_id);
};

const groupSkill = (skill, group) => {
  return group.data?.map(x => x.tag_value_id).includes(skill.tag_value_id);
}

function setUpData(resp){
  skillManagerState.data = [];
  skillManagerState.skillLoading = false;
  skillManagerState.data = resp.data.skill_data;
  skillManagerState.tagData = resp.data.tag_data;
  skillManagerState.selectedSkillTag = skillManagerState.tagData.tagTypes.find(x => x.tag_type_name === "Skills");
  skillManagerState.selectedSkillCategories = [...new Set(skillManagerState.tagData.tagCategories.map(x => { return x.name }))];
  let valid = resp.data.hierarchies.map(x => x.hr_id);
  skillManagerState.filteredHierarchies = store.state.hierarchies.hierarchies[0].values.filter(x => valid.includes(x.value)).map(h => {
    let group = skillManagerState.reviewGroups.find(x => x.framework_review_id === skillManagerState.activeReviewGroup)
    let level = `hierarchy_node_id_level${group?.level+1}`;
    let source = resp.data.hierarchies.find(x => x.hr_id === h.value)
    return {
        hr_id: h.value,
        label: h.textValue,
        skills_data: source.data,
        node_id: h[level],
        docs: source.docs
      }
  });

  skillManagerState.newHrGroup = {
    name: "",
    data: [],
    available: [],
    framework_review_link_id: -1,
    hrs: []
  };

  showHierarchyGroupData(resp.data.group_links, resp.data.reviewNode);

}

function showHierarchyGroupData(data, node){
  let hrList = data.reduce((acc, item) => {
    item.hrs.forEach(hr => {
      if (!acc.includes(hr.node_id)) {
        acc.push(hr.node_id);
      }
    });
    return acc;
  }, []);

  let availableNodes = findNodeWithChildren(store.state.hierarchies.hierarchies[0].treePickerOptionsActive, node);
  availableNodes.children.forEach(x => {
    x.disabled = hrList.includes(x.node_id) ? 'disabled':'';
  })

  skillManagerState.nodeHrs = [];
  skillManagerState.nodeHrs = [JSON.parse(JSON.stringify(availableNodes))]
  skillManagerState.reviewGroupLinks = data;
}

function loadReviewGroup(id){
  skillManagerState.activeReviewGroup = id;
  loadReviewGroupData(id);
}

function loadReviewGroupData(id){
  skillManagerState.skillLoading = true;
    axios.get("skills/skillFrameworkReviewGroupData/" + id)
    .then((resp) => {
      setUpData(resp);
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function findNodeWithChildren(array, targetNodeId) {
  function search(nodes) {
    for (let node of nodes) {
      if (node.node_id === targetNodeId) {
        return node; // Return the node if it matches the targetNodeId
      }
      if (node.children && node.children.length > 0) {
        const found = search(node.children);
        if (found) {
          return found; // Return the matching node from children
        }
      }
    }
    return null; // Return null if no match is found
  }

  return search(array);
}

function getSkillFrameworkReviewGroups() {
    skillManagerState.skillLoading = true;
    axios.get("skills/skillFrameworkReviewGroups/")
    .then((resp) => {
      skillManagerState.framework = resp.data.framework;
      skillManagerState.reviewGroups = resp.data.review_groups;
      initHierarchies();
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function getInstance() {
    getSkillFrameworkReviewGroups();
}

function setPriorities(e, item){
  let link = skillManagerState.reviewGroupLinks.find(x => x.framework_review_link_id === item.framework_review_link_id);
  let data = item.data.map((d,dIdx) => {
    return {
      ...d,
      priority: dIdx+1
    }
  });
  link.data = data;
  if(e.added){
    let removed = link.available.findIndex(x => x.tag_value_id === e.added.element.tag_value_id)
    if(removed > -1){
      link.available.splice(removed, 1);
    }
  }

  axios.post("skills/savePrioritySkills/", data)
  .then((resp) => {
    triggerNotification("Priority Skills Saved Successfully","success");
  })
  .catch(err => {
    console.log(err);
  });  
}

function docLoaded(){
    skillManagerState.loading = store.state.docs.loading;
    if(!skillManagerState.hierarchies){
    skillManagerState.hierarchies = store.state.hierarchies.hierarchies.map((h) => {
          switch (h.label.toLowerCase()) {
            case "geography":
            case "location":
              h.icon = "mdi-map-marker-outline";
              break;
            default:
              h.icon = "mdi-menu-down";
              break;
          }
          h.treePickerValue = null;
          return h;
      });
    }

}

function initHierarchies(){
  skillManagerState.activeHierarchy = [];
  let existing = skillManagerState.reviewGroups.map(x => x.hr_node_id);
  let hierarchy = store.state.hierarchies.hierarchies;
  /*hierarchy[0].treePickerOptionsActive = markDisabled(hierarchy[0].treePickerOptionsActive, existing);
  hierarchy[0].treePickerOptionsActive.forEach(t => {
    t.isDisabled = true;
  })*/
  skillManagerState.hierarchies = hierarchy;

  let clone = Object.assign([], hierarchy[0].treePickerOptionsActive);
  markAsDisabled2(clone, 2);
  skillManagerState.activeHierarchy = clone;
  skillManagerState.activeHierarchy = skillManagerState.activeHierarchy.map(object => object);
}

function createNewReviewGroup(item){
  createReviewGroup(item.node_id, item.name);
}

function markAsDisabled2(data, target, level = 1) {
  data.forEach(item => {
    item.active = level === target;
    let rg = skillManagerState.reviewGroups.find(x => x.hr_node_id === item.node_id);
    if(rg){
      item.framework_review_id = rg.framework_review_id;
    }
    if (item.children && Array.isArray(item.children)) {
      markAsDisabled2(item.children, target, level + 1);
    }
  });
}

function assignSkill(skill, hr){
  if(!hr.skills_data){
    hr.skills_data = [];
  }
  let index = hr.skills_data?.findIndex(sd => sd.tag_value_id === skill.tag_value_id);
  if(index === -1){
    hr.skills_data.push({...fallbackRecord(), tag_value_id: skill.tag_value_id, framework_item_id: skill.framework_item_id});
  }
}

function doSomething(rating_id, skill, hr){
  let index = hr.skills_data.findIndex(sd => sd.tag_value_id === skill.tag_value_id);
  let data = index !== -1 ? hr.skills_data[index] : {...fallbackRecord()};
  data = {
    ...data,
    tag_value_id: skill.tag_value_id,
    tag_rating_id: rating_id,
    hr_node_id: hr.node_id,
    framework_review_id: skillManagerState.activeReviewGroup
  }

  axios.post("skills/saveSkillFrameworkData", data)
  .then((resp) => {
    index !== -1 ? hr.skills_data.splice(index, 1, resp.data.Data) : hr.skills_data.push(resp.data.Data);
    triggerNotification("Saved Successfully","success");
  })
  .catch(err => {
    console.log(err);
  });
}

function triggerNotification(msg, type){
  skillManagerState.notification = {
    text: msg,
    type: type
  };
}

function extractIds(data) {
  return data.flatMap(item => {
    const ids = typeof item.id === "number" || !isNaN(item.id) ? [{ hr_id:item.id, node_id: item.node_id, label: item.name, skills_data: item.skills_data }] : []; // Add the current item's id
    if (item.children && Array.isArray(item.children)) {
      ids.push(...extractIds(item.children)); // Recursively extract ids from children
    }
    return ids;
  });
}

function onHrySelect(hry, nodes){
  skillManagerState.createNewReviewGroupNodeId = null;
  if(nodes.length === 1 && nodes[0].children.length > 0 && nodes[0].parents.length > 0 && !skillManagerState.reviewGroups.some(x => x.hr_node_id === nodes[0].node_id)){
    skillManagerState.createNewReviewGroupNodeId = nodes[0].node_id;
  }
}

function pickRelevantHrNodes(test, nodes){
  console.log(nodes.map(x => x.node_id));
}

function editReviewGroup(g){
  skillManagerState.editReviewGroup = g;
  axios.get("skills/skillFrameworkReviewGroupDataGroups/" + g.framework_review_id)
  .then((resp) => {
    showHierarchyGroupData(resp.data, g.node_id);
  })
  .catch(err => {
      console.log(err);
      skillManagerState.skillLoading = false;
  });
}

function createReviewGroup(node, name){
  let data = {
    node_id: node,
    name: name
  }
  //
  axios.post("skills/skillFrameworkReviewGroups", data)
    .then((resp) => {
      getSkillFrameworkReviewGroups();
      loadReviewGroup(resp.data);
      skillManagerState.createNewReviewGroupNodeId = null;
    })
    .catch(err => {
      console.log(err);
    });
}

function getAllSkills(){
  let data = [];

  skillManagerState.filteredHierarchies.forEach(h => {
    let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(z => z.hr_id === h.hr_id));
    data.push({
      name: h.label,
      docs: docs.map(x => { return {
        doc_id: x.doc_id,
        system_number: x.system_number,
        doc_status_text: x.doc_status_text,
        skills_extracted: h.docs.findIndex(h => h.doc_id === x.doc_id) > -1
      }})
    })    
  })

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false
  };
}

function getSkills(hry){
  let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(h => h.hr_id === hry.hr_id));
  let data = [];
  data.push({
    name: hry.label,
    docs: docs.map(x => { return {
      doc_id: x.doc_id,
      system_number: x.system_number,
      doc_status_text: x.doc_status_text,
      skills_extracted: hry.docs.findIndex(h => h.doc_id === x.doc_id) > -1
    }})
  })

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false
  };
}

function backToGroups(){
  skillManagerState.activeReviewGroup = null;
  skillManagerState.data = [];
  skillManagerState.skillLoading = false;
  skillManagerState.tagData = [];
  skillManagerState.selectedSkillTag = [];
  skillManagerState.selectedSkillCategories = [];
  skillManagerState.filteredHierarchies = []
}

function getNewSkills(data){
    let existing = skillManagerState.data.map(x => x.name);
    let available = data.filter(x => !existing.includes(x.name));
    skillManagerState.addSkillsDialog.data = available;
}

function confirmAddSkill(){
  skillManagerState.data = [
    ...skillManagerState.addSkillsDialog.selected,
    ...skillManagerState.data
  ]
}

function addNewSkill(){
  skillManagerState.addSkillsDialog = {
      show: true,
      selected: [],
      data:[]
  };
  axios.get("skills/getAllFrameworkData/")
    .then((resp) => {
      getNewSkills(resp.data);
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });

}

function createGroup(){
  let newItem = Object.assign({}, skillManagerState.newHrGroup);

  let data = {
    ...newItem,
    framework_review_id: skillManagerState.editReviewGroup.framework_review_id
  }
  axios.post("skills/createReviewGroupLink", data)    
  .then((resp) => {
    newItem.framework_review_link_id = resp.data.framework_review_link_id
    skillManagerState.reviewGroupLinks.push(newItem);
    triggerNotification("Hierarchy Link Group Created Successfully","success");
    skillManagerState.newHrGroup = {
      name: "",
      data: [],
      available: [],
      framework_review_link_id: -1,
      hrs: []
    };
    showHierarchyGroupData(skillManagerState.reviewGroupLinks, skillManagerState.editReviewGroup?.node_id);
  })
  .catch(err => {
    triggerNotification(err,"error");
    skillManagerState.extractSkillsDialog.loading = false;
    skillManagerState.newHrGroup = {
      name: "",
      data: [],
      available: [],
      framework_review_link_id: -1,
      hrs: []
    };
  });


}

function getSkillsForDocs(){
    let data = {
      doc_ids: skillManagerState.extractSkillsDialog.selected,
      activeReviewGroupId: skillManagerState.activeReviewGroup
    }

    skillManagerState.extractSkillsDialog.loading = true;

    axios.post("skills/getSkillsForDocs", data)
    .then((resp) => {
      skillManagerState.extractSkillsDialog.loading = false;
      setUpData(resp);
      skillManagerState.extractSkillsDialog.show = false;
      triggerNotification("Skills Extracted Successfully","success");
    })
    .catch(err => {
      console.log(err);
      skillManagerState.extractSkillsDialog.loading = false;
    });
}

function extractAllSKills(){
  let data = [];
  let docList = skillManagerState.filteredHierarchies.flatMap(item => item.docs.map(doc => doc.doc_id));
  skillManagerState.reviewGroupLinks.forEach(item => {
    let hrs = item.hrs.map(x => x.hr_id);
    let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(h => hrs.includes(h.hr_id)));
    data.push({
      name: item.name,
      docs: docs.map(x => { return {
        doc_id: x.doc_id,
        system_number: x.system_number,
        doc_status_text: x.doc_status_text,
        skills_extracted: docList.includes(x.doc_id)
      }})
    })
  });

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false
  };
}

function deleteItem(skill, item){
  let target = item.skills_data.find(x => x.tag_value_id === skill.tag_value_id);
  if(target){
    axios.delete("skills/skillFrameworkData/" + target.framework_data_id)
    .then((resp) => {
      let idx = item.skills_data.indexOf(target);
      if(idx > -1){
        item.skills_data.splice(idx, 1);
      }
      triggerNotification("Skill Deleted Successfully","success");
    })
    .catch(err => {
      triggerNotification(err,"error");
    });  
  }
}

function deleteSkill(skill){

  let data = {
    ...skill,
    active: false
  }

  axios.post("skills/frameworkSkill", data)
    .then((resp) => {
      let idx = skillManagerState.data.findIndex(x => x.framework_item_id === skill.framework_item_id);
      if(idx > -1){
        skillManagerState.data.splice(idx, 1);
      }
      triggerNotification("Skill Deleted Successfully","success");
    })
    .catch(err => {
      triggerNotification(err,"error");
    });  
  //
}

//#endregion

//#region Component
onUpdated(() => {

})

onBeforeMount(() => {
    getInstance();
    watch(() => store.state.docs.loading, () => docLoaded());
})
//#endregion
</script>
<template>
  <v-card class="skillsWorkspace" flat>
    <div v-if="!skillManagerState.framework?.framework_id">
      No Framework Config!
    </div>
    <div v-else-if="skillManagerState.activeReviewGroup === null" class="px-3">
      Select Review Group:<br/>
      <div v-if="skillManagerState.hierarchies.length > 0 && 0 === 1" style="display:inline-flex">
        <div style="width:300px" class="mr-5">
          <HierarchyTreePicker
            v-model="skillManagerState.hierarchies[0].treePickerValue"
            :label="`${skillManagerState.hierarchies[0].label} *`"
            :options="skillManagerState.hierarchies[0].treePickerOptionsActive"
            placeholder="Please select"
            :multiple="true"
            :fulldetail="true"
            :hierarchyType="skillManagerState.hierarchies[0]"
            dense
            @changeNodeIds="onHrySelect(skillManagerState.hierarchies[0], ...arguments)"
            @nodeAdded="nodeAdded(skillManagerState.hierarchies[0], ...arguments)"
            @lostFocus="skillManagerState.hierarchies[0].lostFocus = true"
            :returnFullDetail="true"
          >
          </HierarchyTreePicker>
        </div>
        <v-btn class="pl-5" :disabled="!skillManagerState.createNewReviewGroupNodeId" @click="createReviewGroup">Create New Review Group</v-btn>
      </div>

      <div class="table-wrapper">
      <table class="table table-bordered groups" cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th width="90%">Review Group:</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="g in skillManagerState.reviewGroups" :key="g.framework_review_id">
            <td>
              {{ g.name }}
            </td>
            <td>
              <v-btn icon @click="loadReviewGroup(g.framework_review_id)"><v-icon>mdi-pencil</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <br/><br/>
      </div>

      <v-row dense>
        <v-col cols="3" style="border-right:1px solid green">
          <div v-if="skillManagerState.activeHierarchy">
            <v-treeview :items="skillManagerState.activeHierarchy" item-key="node_id">
              <template v-slot:label="{ item }">
                {{ item.name }}
                <v-icon color="success" small @click="loadReviewGroup(item.framework_review_id)" v-if="item.active && item.framework_review_id" style="padding-left:4px">
                  mdi-pencil
                </v-icon>
                <v-icon color="success" small @click="editReviewGroup(item)" v-if="item.active && item.framework_review_id" style="padding-left:4px">
                  mdi-cog
                </v-icon>
                <v-icon color="#AAAAAA" small @click="createNewReviewGroup(item)" v-if="item.active && !item.framework_review_id" style="padding-left:4px">
                  mdi-plus-circle
                </v-icon>
              </template>
            </v-treeview>
          </div>
        </v-col>
        <v-col cols="9">
          <v-row dense>
            <v-col cols="3">
              <v-text-field v-model="skillManagerState.newHrGroup.name" style="width:300px" dense></v-text-field><br/>
              <v-btn :disabled="skillManagerState.newHrGroup.hrs.length === 0" small @click="createGroup">Create Hierarchy Group</v-btn><br/>
              <div style="padding-top:15px">
                <v-treeview item-disabled="disabled" selectable :items="skillManagerState.nodeHrs" v-model="skillManagerState.newHrGroup.hrs" item-key="node_id" return-object></v-treeview>
              </div>
            </v-col>
            <v-col cols="9">
              <table class="table table-bordered" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr v-for="(g, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${g.framework_review_link_id}`" style="border:1px solid black">
                    <td style="position: relative; vertical-align: top; padding-bottom:15px">
                      <div class="headerDisplay">
                        {{ g.name }}
                      </div>
                    </td>
                    <td style="position: relative; vertical-align: top; padding-bottom:15px; padding-top: 3px;">
                      <div v-for="(h, hIdx) in g.hrs" :key="`${gIdx}_${g.framework_review_link_id}_${hIdx}`">{{h.name}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="skillManagerState.skillLoading">
        <div style="width:100vw; height:100vh">
          <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          style="top: 40%; left: 48%;"
        ></v-progress-circular>
        </div>

      </div>
      <div v-else>
        <v-row dense style="padding-top: 30px;">
          <v-col cols="11"><v-btn small @click="backToGroups" class="mr-5">Back</v-btn>Skill Distribution Across Job Families - {{  skillManagerState.activeReviewGroup }}<v-icon class="ml-5" @click="getAllSkills">mdi-head-lightbulb</v-icon></v-col>
          <v-col cols="1">
            <v-icon title="Skill Proficiency Distribution" class="mr-2" @click="skillManagerState.activeView = 'skill_proficiency'">mdi-head-lightbulb</v-icon>
            <v-icon title="Job Hierarchy Distribution" class="mr-2" @click="skillManagerState.activeView = 'job_hierarchy'" v-if="skillManagerState.reviewGroupLinks.length > 0">mdi-file-tree-outline</v-icon>
          </v-col>
        </v-row>     
        <v-row dense style="padding-top: 10px;" v-if="skillManagerState.activeView === 'skill_proficiency'">
          <v-col cols="11">
            <div class="table-wrapper distribution-wrapper">
              <div class="table-scroll">
                <table class="table table-bordered distributionTable" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="locked-column intersect-column">Skill ({{ filteredData.length }}) 
                        <v-icon color="#AAAAAA" class="ml-3" @click="addNewSkill">mdi-plus-circle</v-icon> Add Skill</th>
                      <th v-for="item in skillManagerState.filteredHierarchies" :key="`hdr_${item.hr_id}_${rerender}`">
                        <div style="display:inline">
                          <div class="headerText">
                            {{item.label}}
                          </div>
                          <v-icon class="headerIcon" @click="getSkills(item)" x-small>mdi-cog</v-icon>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="filteredData.length > 0">
                    <tr v-for="(skill, idx) in filteredData" :key="`${idx}_${rerender}`">
                      <td class="locked-column">
                        {{skill.name}}
                        <v-menu
                          bottom
                          attach
                          right
                          min-width="220px"
                          :nudge-bottom="4"
                          :nudge-right="41"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div style="position:relative; float:right; bottom: 5px; right: 5px;">
                              <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#48A401" style="float: right;  ">mdi-dots-horizontal</v-icon>
                            </v-btn>
                            </div>

                          </template>

                          <v-list dense>
                            <v-list-item @click="deleteSkill(skill)">
                              <v-list-item-icon class="mr-3">
                                <v-icon>mdi-delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>Delete Skill</v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                      <!--<td v-for="item in skillManagerState.filteredHierarchies" :key="`${idx}_${item.hr_id}_${rerender}`">
                        <div v-if="skillExists(skill, item)">
                          {{ item.name  }}
                        </div>
                        <div v-else>Test</div>
                      </td>--> 
                      <td v-for="item in skillManagerState.filteredHierarchies" :style="{ 'background-color': skillExists(skill, item) ? skill.color : '' }" :key="`${idx}_${item.hr_id}`">
                        <div v-if="skillExists(skill, item)" style="display: inline-flex;width: 100%;align-items: center;justify-content: space-between;">
                          <div style="width:130px; margin-left: 20px;">
                            <v-select dense :value="getModelValue(skill, item)" @change="doSomething($event, skill, item)" style="width:130px; top:7px" :items="skillManagerState.selectedSkillTag.ratings" item-value="tag_type_rating_id" item-text="name">
                              <template v-slot:selection="{ item }">
                                <span
                                  class="grey--text text-caption"
                                  style="width:100px; text-align: center;"
                                >
                                {{ item.name  }}
                                </span>
                              </template>
                            </v-select>
                          </div>
                          <div style="float: right;right: 5px;position: relative;">
                           
                            <v-menu
                              bottom
                              attach
                              right
                              min-width="220px"
                              :nudge-bottom="25"
                              :nudge-right="25"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="#48A401">mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>

                              <v-list dense>
                                <v-list-item @click="deleteItem(skill, item)">
                                  <v-list-item-icon class="mr-3">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>Remove Mapping</v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-icon class="mr-3">
                                    <v-icon>mdi-comment-quote-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>Comment</v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                        <div v-else>
                          <div @click="assignSkill(skill, item)" class="addSkill" style="color:#AAAAAA">
                            <v-icon color="#AAAAAA">mdi-plus-circle</v-icon> Add Level
                          </div>
                        </div>
                      </td>          
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="locked-column">No Skills - Click To Extract</td>
                      <td :colspan="skillManagerState.filteredHierarchies.length"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-col>
          <v-col cols="1">
            Skill Categories:<br/>
            <div v-for="item in skillManagerState.tagData?.tagCategories" :key="item.tag_category_id" :style="{ 'margin-right':'10px', 'background-color': item.color}">
              <v-checkbox
                v-model="skillManagerState.selectedSkillCategories"
                :label="item.name"
                :value="item.name"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row> 
        <div v-if="skillManagerState.activeView === 'job_hierarchy'">
          <v-row dense style="padding-top: 5px;">
            <v-col>
              Job Hierarchy View <v-icon class="ml-5" @click="extractAllSKills">mdi-head-lightbulb</v-icon>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <div class="table-wrapper prioritisation-wrapper">
                <div class="table-scroll">
                  <table class="table table-bordered prioritisationTable" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th v-for="(g, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${g.framework_review_link_id}`">
                          {{ g.name }}
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="filteredData.length > 0">
                      <tr>
                        <td v-for="(item, idx) in filteredHierarchyGroupData" :key="`${idx}_${item.framework_review_link_id}_${rerender}`">
                            <draggable
                            tag="div"
                            :list="item.data"
                            :group="`group_${idx}`"
                            handle=".handle"
                            :emptyInsertThreshold="20"
                            @change="setPriorities($event, item)"
                            min-height="200px"
                            >
                            <template #header v-if="item.data.length == 0">
                              <div class="dropZonePlaceholder">
                                Add Priority Skills
                              </div>
                            </template>
                              <div v-for="(d, dIdx) in item.data" :key="`${idx}_${dIdx}`" class="dragSkill" :style="{ 'background-color':d.color}" >
                              <div class="handle">
                                <v-icon>mdi-dots-grid</v-icon>
                                <span style="padding-left: 3px; padding-top: 1px;" v-if="d.priority">{{ d.priority }}</span>
                              </div>
                              <div class="value">{{ d.value }}</div>
                              <div class="actions">
                                <v-icon color="#48A401">mdi-dots-horizontal</v-icon>
                              </div>
                              
                            </div>
                            </draggable>
                            <hr style="margin-top:10px; margin-bottom:10px"/>
                            <draggable
                            tag="div"
                            :list="item.available"
                            :group="`group_${idx}`"
                            handle=".handle"
                            :emptyInsertThreshold="20"
                            >
                              <div v-for="(d, dIdx) in item.available" :key="`${idx}_${dIdx}`" class="dragSkill" :style="{ 'background-color':d.color}"  >
                              <div class="handle">
                                <v-icon>mdi-dots-grid</v-icon>
                              </div>
                              <div class="value">{{ d.value }}</div>
                              <div class="actions">
                                <v-icon color="#48A401">mdi-dots-horizontal</v-icon>
                              </div>
                              
                            </div>
                            </draggable>                            
                        </td>  
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td class="locked-column">No Skills - Click To Extract</td>
                        <td :colspan="skillManagerState.filteredHierarchies.length"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-col>
            <v-col cols="1">
            Skill Categories:<br/>
            <div v-for="item in skillManagerState.tagData?.tagCategories" :key="item.tag_category_id" :style="{ 'margin-right':'10px', 'background-color': item.color}">
              <v-checkbox
                v-model="skillManagerState.selectedSkillCategories"
                :label="item.name"
                :value="item.name"
              ></v-checkbox>
            </div>
            </v-col>  
          </v-row>
        </div>

        <v-dialog v-model="skillManagerState.extractSkillsDialog.show" max-width="1200px" scrollable>
          <v-card>
            <v-card-title class="d-flex align-center">
              Extract Skills:
            </v-card-title>
            <v-card-text>
              <div v-for="(g,gIdx) in skillManagerState.extractSkillsDialog.data" :key="gIdx">
                <b>{{ g.name }}</b><br/>
                <div v-for="d in g.docs" :key="d.doc_id" >
                  <div style="display:inline-flex; align-items: center; width:100%;">
                    <v-checkbox v-model="skillManagerState.extractSkillsDialog.selected" :value="d.doc_id" :disabled="d.skills_extracted"></v-checkbox>
                    <div class="flow-font-small" style="display:inline-flex; width:100%; padding-top: 5px;">
                        <div>{{ d.system_number }}</div>
                        <v-chip
                          small
                          class="mx-1 status-chip"
                          label
                          >{{ d.doc_status_text }}</v-chip
                        >
                        <div style="font-style:italic; color:green; padding-left:10px" v-if="d.skills_extracted">Skills already extracted from this document</div>
                      </div>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end px-6 pb-4">
              <v-progress-circular
                :size="30"
                color="primary"
                indeterminate
                class="mr-2" v-if="skillManagerState.extractSkillsDialog.loading"
              ></v-progress-circular>
              <v-btn @click="skillManagerState.extractSkillsDialog.show = false">Cancel</v-btn>
              <v-btn :disabled="skillManagerState.extractSkillsDialog.selected.length === 0 || skillManagerState.extractSkillsDialog.loading" color="primary" @click="getSkillsForDocs">Extract Skills</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="skillManagerState.addSkillsDialog.show" max-width="1200px" scrollable>
          <v-card>
            <v-card-title class="d-flex align-center">
              Add Skills:
            </v-card-title>
            <v-card-text>
              <div v-if="skillManagerState.addSkillsDialog.data.length ===0">
                Loading
              </div>
              <div v-else>
                <v-autocomplete multiple :items="skillManagerState.addSkillsDialog.data" v-model="skillManagerState.addSkillsDialog.selected" return-object item-text="name" item-value="id"></v-autocomplete>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end px-6 pb-4">
              <v-btn @click="skillManagerState.addSkillsDialog.show = false">Cancel</v-btn>
              <v-btn :disabled="skillManagerState.addSkillsDialog.selected.length === 0" color="primary" @click="confirmAddSkill">Add Skills</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <Notification :notification="skillManagerState.notification" />
  </v-card>  
</template>
<style lang="scss" scoped>

$skillColumnWidth: 400px;

 * {
  
  font-family: DM Sans !important;
  font-size: 16px;
  font-weight: 400; 
 }

.skillsWorkspace { 
  width: 100%;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);   
}

.headerIcon{
  position:relative;
  z-index:200
}

.addSkill {
  color:#AAAAAA;
  font-size:12px;
  cursor:pointer;
  text-align:center;
}

.addRating{
  color:green;
  font-size:12px;
}

.prioritisation-wrapper {
  height: calc(100vh - 205px);
  max-height: calc(100vh - 205px);
  position: relative;
  width: 98%;
  overflow: hidden;
}

.distribution-wrapper {
  height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  position: relative;
  width: 98%;
  overflow: hidden;
}

.table-scroll {
    overflow: auto;
    max-height: 100%;
    min-height: 100%;
    position: relative;
}

.distributionTable {
  width: 100%;
  border-collapse: collapse;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;   

  thead tr th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border: 1px solid #ccc;
    padding: 8px;
    min-width: 200px;
    max-width: 200px;
    max-height: 20px;
    height: 46px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 10px;
    background-color: #ccc;
    color: black;
    z-index: 9;

    .headerText {
      max-width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 15px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;    
      top: -8px;
    }
  }

  tr {
    width:100%;    
    height: 46px;
    td:not(.locked-column) {

      height: 46px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 200px;
      max-width: 200px;
      word-wrap: break-word;
      border: 1px solid #ccc;
    }
    
  }

  .menuClass {
    z-index:25;
  }

  .locked-column {
  position: sticky;
  left: -1px;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #ccc;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 46px;
  min-width: 400px;
  max-width: 400px;
  padding-left: 25px;
  z-index:9;    
  padding-top: 10px;
}

.intersect-column{
  position: sticky;
  top: -1px;
  left: -1px;
  z-index: 3;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  color: #000;
  padding-left: 25px;  
  text-align: left;
  z-index:10;
}
}

.prioritisationTable {

  max-width:100%;
  border-spacing:10px;

  thead tr th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border: 1px solid #ccc;
    padding: 8px;
    min-width: 300px;
    max-width: 300px;
    max-height: 30px;
    height: 30px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 10px;
    background-color: #ccc;
    color: black;
    border-radius: 5px;
    z-index: 9;

    .headerText {
      max-width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 15px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;
      top: -8px;
    }
  }

  tr {
    width:100%;    
    height: 30px;
    td:not(.locked-column) {
      height: 30px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 300px;
      max-width: 300px;
      max-height: 30px;
      word-wrap: break-word;
      vertical-align: top;
    }
    
  }
}

.groups{
  width: 100%;
  border-collapse: collapse;
}

.groups td, .groups th {
  border: 1px solid #ddd;
  padding: 8px;
}

.groups tr:nth-child(even){background-color: #f2f2f2;}

//.groups tr:hover {background-color: #ddd;}

.groups th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #949494;
  color: white;
}

.dragSkill{
  min-height: 40px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    left: -1px;
    background-color: #fff;
    z-index: 2;
    border: 1px solid #ccc;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 5px 10px 5px 10px;
    border-radius: 10px;    
    margin-top: 3px;

    .handle {
      width: 15%;
      cursor: move;
      display: inline-flex;
      background-color: #EEEEEE;
      border-radius: 5px;
    }

    .value {
      width: 75%;
      text-align: left;
      padding-left: 12px;
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.83px;
      letter-spacing: 0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

    }

    .actions {
      width: 10%;
    }
}

.dropZonePlaceholder {
  height: 40px;
  border: 1px solid #a7a6a6ee;
  border-radius: 10px;
  border-style: dashed;
  padding-top: 6px;
  margin-top: 3px;
  color:#a7a6a6ee;
}

.headerDisplay {
  top: -1px; 
  z-index: 1; 
  border: 1px solid #ccc; 
  padding: 8px;
  min-width: 300px;
  max-width: 300px;
  max-height: 30px;
  height: 30px;
  line-height: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  background-color: #ccc;
  color: black;
  border-radius: 5px;
  align-content: center;
  vertical-align: top;
  position: relative;
  top: 0px;  
}

</style>


const d3 = require("d3");
const dimple = require("../common/dimple.js");
const chartHelper = {
	pieLabels: function (svg) {
		return function (shape, data) {
			var arc = d3
				.arc()
				.outerRadius(data.outerRadius)
				.innerRadius(data.innerRadius);
			//chartHelper.preselectElement(arc, chart, data.value);
			//chartHelper.preselectSelected(chart);

			const ctm = shape.getCTM();
			const centroid = arc.centroid(data);

			if (centroid[0] && centroid[1] && data.piePct > 0.05) {
				svg
					.append("text")
					// Position text in the centre of the shape
					.attr("x", centroid[0])
					.attr("y", centroid[1])
					.attr("class", "barLabel")
					.attr("transform", function () {
						return "translate(" + ctm.e + "," + ctm.f + ")";
					})
					// Centre align and nicer display
					.style("text-anchor", "middle")
					.style("font-size", "10px")
					.style("opacity", 0.8)
					// Prevent text cursor on hover and allow tooltips
					.style("pointer-events", "none")
					// Display text
					.text((data.piePct * 100).toFixed(0) + "%");
			}
		};
	}
};

export default {
	drawChart(definition) {
		this.setChartSize(definition);
		d3.select(definition.element).selectAll("*").remove();
		let svg = dimple.newSvg(definition.element, definition.width, definition.diameter),
			pChart = new dimple.chart(svg, JSON.parse(JSON.stringify(definition.chartData)));

		pChart.addMeasureAxis("p", definition.measure);
		this.assignChartColours(definition, pChart);

		pChart.setBounds(0, 0, definition.diameter, definition.diameter); // x,y,w,h
		const ring = pChart.addSeries(definition.dimension, dimple.plot.pie);
		ring.innerRadius = definition.centralTitle ? "60%" : "50%";
		if (!definition.hideLegend)
			pChart.addLegend(definition.diameter * 1.2, "10%", definition.width - (definition.diameter * 1.2), "90%", "left");

		ring.addEventHandler("click", function (ev) {
			definition.vm.clickHandler(ev);
		});
		ring.getTooltipText = (ev) => {
			let aggField = ev ? ev.aggField || ev.target._current.aggField : [];
			if (aggField.length) {
				let evv = ev.aggField ? ev : ev.target._current;
				return [
					`${aggField[0]}:`,
					`${d3.format(",")(evv.pValue)} (${d3.format(".1f")(
						evv.piePct * 100
					)}%)`,
				];
			}
			else return [];
		};

		if (!definition.hideLabels)
			ring.afterDraw = chartHelper.pieLabels(svg, this.chartDef);

		if (definition.centralTitle) {
			let lines = definition.centralTitle.split(" ").filter(x => x);
			const height = 17;
			let top = (definition.diameter - (height * lines.length)) / 2 + 8;
			lines.forEach((l, li) => {
				let y = top + (li * height) + 4;
				svg.append("text")
				.attr("x", definition.diameter / 2)
				.attr("y", y) 
				.attr("class", "centralTitle")
				// Centre align
				.style("text-anchor", "middle")
				.style("font-size", "12px")
				.style("font-weight", "bold")
				.text(l)
			});
		}

		pChart.draw();

		definition.chart = pChart;
		definition.bars = ring;
	},
	setChartSize(definition) {
		if (definition.chart) {
			definition.chart.svg
				.attr("width", definition.chartWidth)
				.attr("height", definition.chartHeight);
			d3.select(definition.element).selectAll(".centralTitle").remove();
			d3.select(definition.element).selectAll(".barLabel").remove();
			definition.chart.draw(5);
		}
	},
	redrawChart(definition) {
		let redraw = false;
		const measure = definition.measure;
		const dimension = definition.dimension;

		definition.chartData.forEach((x) => {
			let v = definition.chart.data.find(
				(cd) => cd[dimension] === x[dimension]
			);
			if (v) {
				if (v[measure] != x[measure]) {
					v[measure] = x[measure];
					redraw = true;
				}

				if (v.labelText) {
					v.labelText = x.labelText;
					v.labelPct = x.labelPct;
				}
			} else {
				definition.chart.data.push(x);
				redraw = true;
			}
		});

		definition.chart.data.forEach((v) => {
			if (!definition.chartData.some(cd => cd[dimension] === v[dimension])) {
				v[measure] = 0;
				redraw = true;
			}
		});

		if (redraw) {
			d3.select(definition.element).selectAll(".barLabel").remove();
			d3.select(definition.element).selectAll(".centralTitle").remove();
			definition.chart.draw(200);
		}
	},
	assignChartColours(definition, chart) {
		const dimensionColours = definition.dimensionColours || [];
		let getColour = (v) => {
			let col = definition.dimensionColours.find(
				(dc) => dc.value === v
			);
			if (!col) {
				col = { value: v, colour: definition.colours[dimensionColours.length] };
				dimensionColours.push(col);
			}
			return col.colour;
		};
		let processed = [];
		definition.chartData.forEach((cd) => {
			let value = cd[definition.dimension];
			if (processed.indexOf(value) < 0) {
				processed.push(value);
				chart.assignColor(value, getColour(value));
			}
		});
	}
}
<template>
  <v-container>
    <v-row class="sectionTitle" v-if="section.label || section.section">
      <v-col class="pt-0">
        <h2>{{ section.label || section.section }}</h2>
      </v-col>
    </v-row>
    <v-row class="chartRow">
      <v-col :id="chartId" class="chart-container">
      </v-col>
      <div class="centralTitle" v-if="section.view && section.view.centralTitle" >
        <div v-html="section.view.centralTitle"></div>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import utils from "@/common/utils.js";
const d3 = require("d3");
const dimple = require("../common/dimple.js");
export default {
  name: "DocumentDashboardPieChart",
  components: {},
  props: {
    section: Object,
    settings: Object
  },
  data: function () {
    return {
      response: null,
      editSettings: false,
      selectedView: null,
      headerHeight: 0,
      chartId: "chart_" + this.makeid(10),
      element: null,
      dimension: "key",
      measure: "",
      chartWidth: 0,
      chartHeight: 0,
      chart: null,
      bars: null,
      colours: [
        "#2d99ff", // Blue
        "#2cd9c5", // Green
        "#FF4069", // Red
        "#FF9020", // Orange
        "#FFED6F", // Yellow
        "#BC80BD", // Purple
        "#8DD3C7", // Turquoise
        "#CCEBC5", // Pale Blue
        "#FFFFB3", // Pale Yellow
        "#BEBADA", // Lavender
        "#FCCDE5", // Pink
        "#D9D9D9", // Grey,
      ],
    };
  },
  updated() {
    this.selectedView = this.section.view?.name;
    this.setHeaderHeight();
  },
  mounted() {
    this.setHeaderHeight();
    this.measure = this.getMeasures()[0].measureColumn;
    this.element = document.getElementById(this.chartId);
    this.$nextTick(() => {
      this.drawChart();
      window.addEventListener('resize', this.setChartSize);
    });
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.setChartSize);
  },
  watch: {
    chartData() {
      this.$nextTick(() => {
        this.redrawChart();
      });
    },
  },
  computed: {
    chartData() {
      return this.section.view?.data || [];
    }
  },
  methods: {
    makeid(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let result = '';
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    setHeaderHeight() {
      this.headerHeight = this.$refs.header
        ? this.$refs.header.offsetHeight + 145
        : 165;
    },
    clickHandler(ev) {
      if (ev.seriesValue && ev.seriesValue.length)
        this.rowSelected(ev.seriesValue[0]);
    },
    rowSelected(value, ignore) {
      if (ignore) return;
      if (
        this.section.view.filterCriteria.length &&
        !this.section.view.filterCriteria[0].ht_id
      ) {
        this.removeFilter(0);
      } else if (
        this.section.view.data.length > 1 &&
        (this.section.view.allowDrilldown ||
          !this.section.view.filterCriteria.length)
      )
        this.$emit("rowSelected", this.section.view, value);
    },
    changeView(name) {
      this.$emit("changeView", name || this.selectedView);
    },
    removeFilter(level) {
      this.$emit("removeFilter", level);
    },
    showMeasure(measure) {
      this.$emit("showMeasure", measure);
    },
    getMeasures() {
      let d = this.settings.measures.filter((x) =>
        this.settings.activeMeasures.some((m) => m === x.name)
      );
      d.forEach((m) => {
        let sort =
          this.settings.sortBy && this.settings.sortBy.measure === m.name
            ? this.settings.sortBy
            : null;
        m.sort = sort ? (sort.desc ? "desc" : "asc") : "";
      });
      return d;
    },
    setSort(item) {
      if (item && this.section.view.scoreBandGroupBy) return;
      this.$emit("changeSort", this.settings, item || "key");
    },
    exportToCSV() {
      let data = `"${this.section.section}",`;
      let cols = this.getMeasures();
      data += cols.map((c) => `"${c.name}"`).join(",") + "\n";
      this.section.view.data.forEach((d) => {
        //utils.removeTags(d[h.measureColumn])
        data += `${utils.csvEscape(d.key)},`;
        data += cols.map((c) => utils.csvEscape(d[c.measureColumn])).join(",") + "\n";
      });
      utils.downloadFile(
        data,
        `${this.section.section}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
    drawChart() {
      const vm = this;
      
      this.setChartSize();
      d3.select(this.element).selectAll("*").remove();
      let svg = dimple.newSvg(this.element, this.chartWidth, this.chartHeight),
        pChart = new dimple.chart(svg, this.chartData);
      
      pChart.addMeasureAxis("p", this.measure);

      this.assignChartColours(pChart);

      //pChart.setBounds(10, "15%", "70%", "90%"); // x,y,w,h
      pChart.setBounds(0, 0, this.settings.hideLegend ? "100%" : "65%", "100%"); // x,y,w,h
      const ring = pChart.addSeries(this.dimension, dimple.plot.pie);
      ring.innerRadius = this.section.view?.centralTitle ? "70%" : "50%";
      if (!this.settings.hideLegend)
        pChart.addLegend("70%", 0, "35%", "100%", "left");

      ring.addEventHandler("click", function (ev) {
        vm.clickHandler(ev);
      });
      ring.getTooltipText = (ev) => {
        if (ev && ev.aggField && ev.aggField.length)
          return [
            `${ev.aggField[0]}:`,
            `${d3.format(",")(ev.pValue)} (${d3.format(".1f")(
              ev.piePct * 100
            )}%)`,
          ];
        else return [];
      };

      if (!this.settings.hideLabels)
        ring.afterDraw = chartHelper.pieLabels(svg, this.chartDef);

      pChart.draw();

      this.chart = pChart;
      this.bars = ring;
    },
    setChartSize() {
      this.chartWidth = this.element?.parentElement.offsetWidth - 25;
      this.chartHeight = this.settings.hideLegend ? this.chartWidth : this.chartWidth * 0.6;

      if (this.chart) {
        this.chart.svg
          .attr("width", this.chartWidth)
          .attr("height", this.chartHeight);
        d3.select(this.element).selectAll(".barLabel").remove();
        this.chart.draw(5);
      }
    },
    redrawChart() {
      let redraw = false;
      const measure = this.measure;
      const dimension = this.dimension;

      this.chartData.forEach((x) => {
        let v = this.chart.data.find(
          (cd) => cd[dimension] === x[dimension]
        );
        if (v) {
          if (v[measure] != x[measure]) {
            v[measure] = x[measure];
            redraw = true;
          }
          
          if (v.labelText) {
            v.labelText = x.labelText;
            v.labelPct = x.labelPct;
          }
        } else {
          this.chart.data.push(x);
          redraw = true;
        }
      });

      this.chart.data.forEach((v) => {
        if (!this.chartData.some(cd => cd[dimension] === v[dimension])) {
          v[measure] = 0;
          redraw = true;
        }
      });

      if (redraw) {
        d3.select(this.element).selectAll(".barLabel").remove();
        this.chart.draw(200);
      }
    },
    assignChartColours(chart) {
      const dimensionColours = this.section.view?.dimensionColours || [];
      let getColour = (v) => {
        let col = this.section.view.dimensionColours.find(
          (dc) => dc.value === v
        );
        if (!col) {
          col = { value: v, colour: this.colours[dimensionColours.length] };
          dimensionColours.push(col);
        }
        return col.colour;
      };
      let processed = [];
      this.chartData.forEach((cd) => {
        let value = cd[this.dimension];
        if (processed.indexOf(value) < 0) {
          processed.push(value);
          chart.assignColor(value, getColour(value));
        }
      });
    }
  },
  
};
const chartHelper = {
  pieLabels: function (svg) {
    return function (shape, data) {
      var arc = d3
        .arc()
        .outerRadius(data.outerRadius)
        .innerRadius(data.innerRadius);
      //chartHelper.preselectElement(arc, chart, data.value);
      //chartHelper.preselectSelected(chart);

      const ctm = shape.getCTM();
      const centroid = arc.centroid(data);

      if (centroid[0] && centroid[1] && data.piePct > 0.05) {
        svg
          .append("text")
          // Position text in the centre of the shape
          .attr("x", centroid[0])
          .attr("y", centroid[1])
          .attr("class", "barLabel")
          .attr("transform", function () {
            return "translate(" + ctm.e + "," + ctm.f + ")";
          })
          // Centre align and nicer display
          .style("text-anchor", "middle")
          .style("font-size", "10px")
          .style("opacity", 0.8)
          // Prevent text cursor on hover and allow tooltips
          .style("pointer-events", "none")
          // Display text
          .text((data.piePct * 100).toFixed(0) + "%");
      }
    };
  }
}
</script>
<style scoped lang="scss">
.container {
  padding: 6px;
}
.sectionTitle {
  min-height: 56px;
}
.chart-container {
  cursor: pointer;
}
h2 {
  margin: 0px;
}
.chartRow {
  position: relative;
  .centralTitle {
    position: absolute;
    top: calc(50% - 30px);
    left: 0;
    width: 100%;
    height: 100%;
    > div {
      text-align: center;
    }
  }
}
</style>